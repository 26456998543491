// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expiry-date-input-right {
  width: 15em;
}

.cvc-input-right {
  margin-left: auto;
  width: 8em;
}

.horizontal-radio > div {
  margin-right: 2em;
}

.horizontal-radio div {
  display: inline-block !important;
  margin-bottom: 1em;
}`, "",{"version":3,"sources":["webpack://./src/components/paymentPage/PaymentPage.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;AADJ;;AAIA;EACI,iBAAA;EACA,UAAA;AADJ;;AAIA;EACI,iBAAA;AADJ;;AAIA;EACI,gCAAA;EACA,kBAAA;AADJ","sourcesContent":["@import 'assets/scss/global';\n\n.expiry-date-input-right {\n    width: 15em;\n}\n\n.cvc-input-right {\n    margin-left: auto;\n    width: 8em;\n}\n\n.horizontal-radio > div {\n    margin-right: 2em;\n}\n\n.horizontal-radio div {\n    display: inline-block !important;\n    margin-bottom: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
