import React from 'react';
import { Stepper } from '@myob/myob-widgets';
import '../Stepper.scss';

const SubscribeStepper: React.FC<{activeStepNumber: '1'|'2'|'3'}> = ({ activeStepNumber }) =>
  <Stepper className={activeStepNumber === '3' ? 'final-step' : 'stepper'}
    activeStepNumber={activeStepNumber}
    steps={[
      { number: '1', title: 'Account setup', type: activeStepNumber === '1' ? 'incomplete' : 'complete' },
      { number: '2', title: 'Payment details', type: activeStepNumber === '3' ? 'complete' : 'incomplete' },
      { number: '3', title: 'Confirmation', type: activeStepNumber === '3' ? 'complete' : 'incomplete' }
    ]}
  />;

export default SubscribeStepper;
