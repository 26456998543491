import {
  cloneDeep, isPlainObject, mapValues, mapKeys
} from 'lodash';

export const camelToSnake = (object: Record<string, unknown> | undefined): Record<string, unknown> => {
  let camelCaseObject = cloneDeep(object);

  // Convert keys to camel case
  camelCaseObject = mapKeys(camelCaseObject, (value, key) => key.replace(/([A-Z])/g, '_$1').toLowerCase());

  // Recursively apply throughout object
  return mapValues(
    camelCaseObject,
    (value) => {
      if (isPlainObject(value)) {
        return camelToSnake(value as Record<string, unknown>);
      }
      return value;
    }
  );
};
