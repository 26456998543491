export const areCookiesEnabled = (): boolean => {
  // Try to set a test cookie
  document.cookie = 'testcookie';

  // Check if the test cookie has been set
  const cookiesEnabled = document.cookie.indexOf('testcookie') !== -1;

  // Clean up the test cookie
  document.cookie = 'testcookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

  return cookiesEnabled;
};

export const areCookiesDisabled = (): boolean => !areCookiesEnabled();
export const isEmpty = (value: string | undefined): boolean => (value === undefined || value.trim() === '');
