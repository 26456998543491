import React from 'react';
import { Document, Text } from '@contentful/rich-text-types/dist/types/types';
import './Terms.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import { useContentContext } from 'providers/contentProvider/ContentProvider';

export const prefixCaret = (termsConditions: Document) => {
  const contentArray = termsConditions.content[0].content;
  const caretCharacter = '^ ';
  const caretText: Text = {
    nodeType: 'text', value: caretCharacter, marks: [], data: {}
  };
  if (!contentArray.find((content) => content.nodeType === caretText.nodeType && content.value === caretText.value)) {
    contentArray.unshift(caretText);
  }
  return termsConditions;
};

type TermsConditionsProps = {
  className?: string;
}
export const TermsConditions: React.FC<TermsConditionsProps> = ({ className }) => {
  const { promotions } = useContentContext();
  let termsConditions = promotions?.[0]?.terms;
  if (!termsConditions) return null;

  termsConditions = prefixCaret(termsConditions);

  return (
    <div
      className={className}
      data-testid='terms-conditions-test'
    >
      { documentToReactComponents(termsConditions, {
        renderNode: {
          [INLINES.HYPERLINK]: (node, children) =>
            <a href={node.data.uri} target='_blank' rel='noreferrer'>{children}</a>
        }
      })}
    </div>
  );
};
