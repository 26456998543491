import Cookies from 'js-cookie';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { useGetEcommercePrice } from '../../hooks/GTMAnalytics/useGTMAnalyticsHelper';
import {
  COOKIE_AJS_ANONYMOUS_ID,
  COOKIE_AJS_USER_ID,
  COOKIE_MYOB_VISITOR_ID,
  GTM_ANALYTICS_CONSTANTS
} from '../../constants';
import { useSendEventToSegment } from '../../hooks/useSendEventToSegment/useSendEventToSegment';

export const useSendEventToSegmentForPurchase = () => {
  const { orderNumber, paymentMethod } = useSessionContext();
  const { tax, totalPrice } = useGetEcommercePrice();
  const anonymousId = Cookies.get(COOKIE_AJS_ANONYMOUS_ID);
  const myobVisitorId = Cookies.get(COOKIE_MYOB_VISITOR_ID);
  const userId = Cookies.get(COOKIE_AJS_USER_ID);
  const trackParams = {
    event: GTM_ANALYTICS_CONSTANTS.PURCHASE_SEGMENT,
    userId,
    anonymousId,
    properties: {
      myobVisitorId,
      transaction_id: orderNumber,
      value: totalPrice,
      tax,
      shipping: 0,
      payment_type: paymentMethod
    }
  };
  useSendEventToSegment({
    shouldSendSegmentEvent: !!orderNumber,
    trackParams
  });
};
