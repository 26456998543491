// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecaptchaDescription_recaptchaLink__p3GkW {
  color: #74767b;
  text-decoration-color: currentColor;
}

.RecaptchaDescription_recaptchaDescription__AgKsM {
  margin-top: 3rem;
  color: #74767b;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/recaptchaDescription/RecaptchaDescription.module.scss"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,mCAAA;AAAF;;AAGA;EACE,gBAAA;EACA,cAAA;EACA,eAAA;AAAF","sourcesContent":["\n.recaptchaLink {\n  color: #74767b;\n  text-decoration-color: currentColor;\n}\n\n.recaptchaDescription {\n  margin-top: 3rem;\n  color: #74767b;\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recaptchaLink": `RecaptchaDescription_recaptchaLink__p3GkW`,
	"recaptchaDescription": `RecaptchaDescription_recaptchaDescription__AgKsM`
};
export default ___CSS_LOADER_EXPORT___;
