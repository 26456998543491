import { GTM4_ANALYTICS_CONSTANTS as GTM_ANALYTICS_CONSTANTS } from '../../constants';

type EventType = typeof GTM_ANALYTICS_CONSTANTS.PURCHASE
  | typeof GTM_ANALYTICS_CONSTANTS.BEGIN_CHECKOUT
  | typeof GTM_ANALYTICS_CONSTANTS.ADD_PAYMENT_INFO
  | typeof GTM_ANALYTICS_CONSTANTS.ADD_SHIPPING_INFO;

export type EcommerceEvent = {
  event: EventType,
  currency?: string,
  value?: number,
  coupon?: string,
  items: EcommerceItems[] | []
}
export enum UserStatus {
  NEW_USER = 'new user',
  EXISTING_USER = 'existing user'
}

export type UserStatusType = typeof UserStatus.NEW_USER| typeof UserStatus.EXISTING_USER

export type UserStatusEcommerceEvent = EcommerceEvent & {
  user_status: UserStatusType
}

export type PurchaseEvent = EcommerceEvent & {
  transaction_id: string,
  tax?: number,
  shipping: number,
  payment_type: string,
  user_status: UserStatusType
}

export enum MarketingConsent {
  SELECTED = 'true',
  NOT_SELECTED = 'false'
}
export type MarketingConsentType = typeof MarketingConsent.SELECTED | typeof MarketingConsent.NOT_SELECTED
export type EcommerceItems = {
  item_id: string,
  item_name: string,
  coupon?: string,
  discount?: number,
  item_brand: string,
  item_category: string,
  item_free_period: number,
  item_payment_state: 'with payment' | 'without payment',
  item_marketing_consent?: MarketingConsentType,
  price: number,
  quantity: number
}

export type EcommercePrice = {
  totalPriceWithoutTax?: number,
  tax?: number,
  totalDiscountAmt?: number,
  totalPrice?: number,
  currency?: string
}

export type UseCommonEventProps = {
  ecommerceEvent: EcommerceEvent,
  identifyParams?: IdentifyParams,
  shouldPushEvent: boolean,
}

export type IdentifyParams = {
  email?: string,
  last_name?: string,
  first_name?: string,
  business_name?: string,
  phone_number?: string,
  terms_and_conditions?: boolean,
  communication_preference?: boolean
}
