import { FEELIX_THEMES } from '../../../constants';

export const getStyleProps = (theme) => (
  {
    borderRadius: 'md',
    ...(theme === FEELIX_THEMES.MODERN && {
      borderColor: 'borderDefault',
      borderWidth: 'thin',
      borderStyle: 'solid',
      borderRadius: 'xl',
      boxShadow: 'strong',
      paddingBottom: 'xl'
    })
  }
);
