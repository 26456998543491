// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper {
  position: absolute;
  top: 0;
  margin-top: 7rem;
  display: block;
  width: 100%;
}
@media screen and (min-width: 578px) {
  .stepper {
    max-width: 544px;
    margin-left: auto;
    margin-right: auto;
    left: 80px;
    right: 80px;
  }
}
@media screen and (min-width: 578px) and (min-height: 624px) and (max-height: 900px) {
  .stepper {
    position: static;
    position: initial;
    margin-bottom: 2rem;
    transform: scale(0.8);
  }
}

.final-step {
  position: absolute;
  top: 0;
  margin-top: 7rem;
  display: block;
}
.final-step .flx-step--active .flx-step__counter {
  background-color: #00AA65;
}
@media screen and (min-width: 578px) {
  .final-step {
    max-width: 544px;
    margin-left: auto;
    margin-right: auto;
    left: 80px;
    right: 80px;
  }
}

.trial-stepper .final-step {
  max-width: 366px;
}
.trial-stepper .stepper {
  max-width: 366px;
}`, "",{"version":3,"sources":["webpack://./src/components/stepper/Stepper.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,gBAAA;EACA,cAAA;EACA,WAAA;AACF;AAAE;EANF;IAOI,gBAAA;IACA,iBAAA;IACA,kBAAA;IACA,UAAA;IACA,WAAA;EAGF;AACF;AAFE;EAbF;IAcI,gBAAA;IAAA,iBAAA;IACA,mBAAA;IACA,qBAAA;EAKF;AACF;;AAFA;EAKE,kBAAA;EACA,MAAA;EACA,gBAAA;EACA,cAAA;AACF;AARE;EACE,yBAAA;AAUJ;AAHE;EATF;IAUI,gBAAA;IACA,iBAAA;IACA,kBAAA;IACA,UAAA;IACA,WAAA;EAMF;AACF;;AAFE;EACE,gBAAA;AAKJ;AAHE;EACE,gBAAA;AAKJ","sourcesContent":[".stepper {\n  position: absolute;\n  top: 0;\n  margin-top: 7rem;\n  display: block;\n  width: 100%;\n  @media screen and (min-width: 578px) {\n    max-width: 544px;\n    margin-left: auto;\n    margin-right: auto;\n    left: 80px;\n    right: 80px;\n  }\n  @media screen and (min-width: 578px) and (min-height: 624px) and (max-height: 900px) {\n    position: unset;\n    margin-bottom: 2rem;\n    transform: scale(0.8);\n  }\n}\n\n.final-step {\n  .flx-step--active .flx-step__counter {\n    background-color: #00AA65;\n  }\n\n  position: absolute;\n  top: 0;\n  margin-top: 7rem;\n  display: block;\n  @media screen and (min-width: 578px) {\n    max-width: 544px;\n    margin-left: auto;\n    margin-right: auto;\n    left: 80px;\n    right: 80px;\n  }\n}\n\n.trial-stepper {\n  .final-step {\n    max-width: 366px;\n  }\n  .stepper {\n    max-width: 366px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
