// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingOverlay_hidden__dx0oI {
  display: none;
}

.LoadingOverlay_overlay__LV2wo {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.3);
}`, "",{"version":3,"sources":["webpack://./src/components/loading/LoadingOverlay.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AACA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,oCAAA;AAEF","sourcesContent":[".hidden {\n  display: none;\n}\n.overlay {\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  z-index: 999999;\n  background-color: rgba(0, 0, 0, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `LoadingOverlay_hidden__dx0oI`,
	"overlay": `LoadingOverlay_overlay__LV2wo`
};
export default ___CSS_LOADER_EXPORT___;
