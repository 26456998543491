import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';

import { get } from 'lodash';
import moment from 'moment';
import {
  ANALYTICS_CONSTANTS,
  ANALYTICS_VARIANTS,
  SOURCE_SYSTEM,
  SUBSCRIPTION_TYPE
} from '../../constants';
import { useAuth0 } from '../Auth0/Auth0';

export const pushToDataLayer = (eventData: unknown): boolean => {
  const windowRef: any = window;

  if (
    !windowRef.dataLayer ||
    !windowRef.dataLayer.push ||
    typeof windowRef.dataLayer.push !== 'function'
  ) {
    return false;
  }
  windowRef.dataLayer.push(eventData);
  return true;
};

export const useGetEcommerceProduct = (): any[] => {
  const { productData } = useGetProduct();
  const { subscriptionType } = useSessionContext();
  const variant = ANALYTICS_VARIANTS[subscriptionType as SUBSCRIPTION_TYPE];
  const sourceSystem = SOURCE_SYSTEM.WEBSITE;

  const ecommerceProduct = {
    position: 1,
    quantity: 1,
    sourceSystem,
    variant
  };

  const isTrial = subscriptionType === SUBSCRIPTION_TYPE.TRIAL;
  return productData?.items?.map((product) => ({
    ...ecommerceProduct,
    product_id: product.productId,
    price: isTrial ? 0 : Number(product.priceWithoutTax),
    name: product.description + (isTrial ? ANALYTICS_CONSTANTS.TRIAL_SUFFIX : ''),
    category: product.productFamily
  })) || [];
};

export const useGetEcommercePrice = (): any => {
  const { productData } = useGetProduct();
  const { subscriptionType } = useSessionContext();

  if (subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE) {
    return {
      totalPriceWithoutTax: productData.totalPriceWithoutTax,
      tax: productData.tax,
      totalDiscountAmt: productData.totalDiscountAmt,
      totalPrice: productData.totalPrice,
      currency: productData.currency
    };
  }

  return {
    totalPriceWithoutTax: 0,
    tax: 0,
    totalDiscountAmt: 0,
    totalPrice: 0,
    currency: productData.currency
  };
};

export const useGetGeneralEcommerceInfo = (): {
  checkout_id?: string;
  affiliation: string;
  shipping: number;
  coupon?: string;
} => {
  const { promoCode, checkoutId } = useSessionContext();

  return {
    checkout_id: checkoutId,
    affiliation: ANALYTICS_CONSTANTS.AFFILIATION,
    shipping: 0,
    coupon: promoCode
  };
};

export const useGetCheckoutStartedEvent = (): any => {
  const generalEcommerceInfo = useGetGeneralEcommerceInfo();
  const ecommerceEventInformation = useGetEcommercePrice();
  const ecommerceProducts = useGetEcommerceProduct();

  return {
    ...generalEcommerceInfo,
    revenue: ecommerceEventInformation.totalPriceWithoutTax,
    tax: ecommerceEventInformation.tax,
    discount: ecommerceEventInformation.totalDiscountAmt,
    currency: ecommerceEventInformation.currency,
    products: ecommerceProducts
  };
};

export const useGetOrderCompletedEvent = (): any => {
  const {
    contactArchieRowId, productInstanceId, serialNumber, orderNumber, subscriptionType
  } = useSessionContext();
  const isTrial = subscriptionType === SUBSCRIPTION_TYPE.TRIAL;
  const checkoutStartedObject = useGetCheckoutStartedEvent();
  const { user, appState } = useAuth0();
  const businessName = get(appState, 'clientInformation.businessName');
  const contactIdentityId = user?.['https://id.myob.com/user_id'].toLowerCase();
  const checkoutStartedObjectWithExtraFields = {
    ...checkoutStartedObject,
    event: ANALYTICS_CONSTANTS.ORDER_COMPLETED_EVENT,
    order_id: orderNumber,
    user_region: moment.tz.guess()
  };
  if (isTrial) {
    return checkoutStartedObjectWithExtraFields;
  }

  return {
    ...checkoutStartedObjectWithExtraFields,
    contactArchieRowId,
    productInstanceId,
    serialNumber,
    businessName,
    sourceSystem: SOURCE_SYSTEM.WEBSITE,
    payerType: 'Client',
    idamId: contactIdentityId
  };
};

export const useGetPurchaseEvent = (): any => {
  const {
    productId, promoCode, orderNumber
  } = useSessionContext();

  const { productData } = useGetProduct();
  const ecommerceEventInformation = useGetEcommercePrice();
  const ecommerceProduct = useGetEcommerceProduct();
  const coupon = promoCode;
  const { subscriptionType } = useSessionContext();
  const isTrial = subscriptionType === SUBSCRIPTION_TYPE.TRIAL;

  return {
    ecommerce: {
      purchase: {
        actionField: {
          id: orderNumber,
          affiliation: ANALYTICS_CONSTANTS.AFFILIATION,
          revenue: ecommerceEventInformation.totalPrice,
          tax: ecommerceEventInformation.tax,
          shipping: productData.shipping || 0,
          coupon
        },
        products: [{
          id: productId,
          name: ecommerceProduct.length > 0 && ecommerceProduct[0]?.name,
          category: '',
          quantity: 1,
          variant: ANALYTICS_CONSTANTS.PRODUCT_VARIANT,
          price: ecommerceEventInformation.totalPrice,
          coupon,
          serialNumber: ''
        }]
      }
    },
    event: isTrial ? ANALYTICS_CONSTANTS.TRIAL_PURCHASE_EVENT : ANALYTICS_CONSTANTS.PURCHASE_EVENT
  };
};
