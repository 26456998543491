import { get } from 'lodash';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { LEGAL_ENTITIES } from '../../constants';
import { AccountDetails } from './types';

export const useAccount = (): AccountDetails => {
  const { appState } = useAuth0();
  const { region } = useSessionContext();
  const businessName = get(appState, 'clientInformation.businessName');
  return {
    name: businessName,
    legal_entity: get(LEGAL_ENTITIES, region).LEGAL_ENTITY,
    division: 'Business Division',
    ea_business_id: ''
  } as AccountDetails;
};
