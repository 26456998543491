import React from 'react';
import './NewFeatureContainer.scss';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { SUBSCRIPTION_TYPE } from '../../constants';

export const NewFeatureContainer: React.FC = ({
  children
}) => {
  const { subscriptionType } = useSessionContext();
  const clazzName = subscriptionType === SUBSCRIPTION_TYPE.TRIAL ? 'trial-feature-box' : 'new-feature-box';
  return (
    <>
      <div
        data-testid={clazzName}
        className={clazzName}
      >
        {children}
      </div>
    </>
  );
};
