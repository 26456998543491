// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input__field {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.75062em;
  padding: 0.3rem 1rem;
  border-radius: 0.25em;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 400;
  color: #515e6d;
  margin: 0;
}

.flx-loading-spinner--svg {
  display: inline;
}

.loading-font {
  line-height: 24px;
}

#bsb-loading-state div {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/components/paymentPage/directDebitForm/directDebitForm.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EAEA,sBAAA;EACA,WAAA;EACA,iBAAA;EACA,oBAAA;EACA,qBAAA;EACA,oBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,SAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".input__field {\n  display: block;\n  -webkit-box-sizing: border-box;\n  box-sizing: border-box;\n  width: 100%;\n  height: 2.75062em;\n  padding: .3rem 1rem;\n  border-radius: .25em;\n  font-family: inherit;\n  font-size: 1.6rem;\n  font-weight: 400;\n  color: #515e6d;\n  margin: 0;\n}\n\n.flx-loading-spinner--svg {\n  display: inline;\n}\n\n.loading-font {\n  line-height: 24px;\n}\n\n#bsb-loading-state div {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
