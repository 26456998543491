import React from 'react';

type ImageProps = {
  src: string;
  alt: string;
  className?: any;
  onClick?: () => void;
};

export const Image: React.FC<ImageProps> = ({
  src, alt, className, onClick
}) => (
  <img
    className={className}
    src={src}
    alt={alt}
    onClick={onClick}
  />
);
