import { useEffect, useRef } from 'react';

export const useClickHandler = (callback: (clickedOutside: boolean | null) => void) => {
  const ref = useRef<Element | null>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!ref || !ref.current) return;

      callback(!ref.current.contains(event.target as Node));
    };

    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', handleClick);
  }, [callback]);

  return ref;
};
