import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router';
import {
  ANALYTICS_CONSTANTS, GTM_ANALYTICS_EVENTS_CATEGORY, STEP_PATH, SUBSCRIPTION_TYPE
} from '../../constants';
import { EcommerceEvent, EcommerceItems, EcommercePrice } from './types';

export const pushToCheckoutDatalayer = (eventData: EcommerceEvent): boolean => {
  TagManager.dataLayer({
    dataLayer: {
      ...eventData
    },
    dataLayerName: 'CheckoutDataLayer'
  });
  return true;
};

export const useGetEcommerceItems = (): EcommerceItems[] => {
  const { location } = useHistory();
  const { subscriptionType, promoCode, isNewUser } = useSessionContext();
  const { productData } = useGetProduct();
  const { currency, totalDiscountAmt } = useGetEcommercePrice();

  const isTrial = subscriptionType === SUBSCRIPTION_TYPE.TRIAL;
  const isFromWebsite = location.pathname.includes(STEP_PATH.TRIAL.ROOT) ||
      location.pathname.includes(STEP_PATH.SUBSCRIBE.ROOT) || location.pathname.includes(STEP_PATH.NEW_TRIAL.ROOT);

  return productData?.items?.map((product, index) => ({
    item_id: product.productId,
    item_name: product.description + (isTrial ? ANALYTICS_CONSTANTS.TRIAL_SUFFIX : ''),
    affiliation: 'V2 checkout',
    coupon: promoCode,
    currency,
    discount: totalDiscountAmt,
    index,
    item_brand: 'MYOB',
    item_category: isTrial ? 'No Payment Profile' : 'Payment Profile',
    item_category2: isNewUser ? GTM_ANALYTICS_EVENTS_CATEGORY.SIGN_UP : GTM_ANALYTICS_EVENTS_CATEGORY.ADD_BUSINESS,
    item_category3: isFromWebsite ? 'Website' : '',
    item_category4: product.productFamily,
    item_category5: 'Core product',
    item_list_id: undefined,
    item_list_name: window.document.title,
    item_variant: '',
    location_id: '',
    price: isTrial ? 0 : Number(product.priceWithoutTax),
    quantity: 1
  })) || [];
};

export const useGetEcommercePrice = (): EcommercePrice => {
  const { productData } = useGetProduct();
  const { subscriptionType } = useSessionContext();

  if (subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE) {
    return {
      totalPriceWithoutTax: productData.totalPriceWithoutTax,
      tax: productData.tax,
      totalDiscountAmt: productData.totalDiscountAmt,
      totalPrice: productData.totalPrice,
      currency: productData.currency
    };
  }

  return {
    totalPriceWithoutTax: 0,
    tax: 0,
    totalDiscountAmt: 0,
    totalPrice: 0,
    currency: productData.currency
  };
};

export const useGetBeginCheckoutAndAccountSetupEvent = (): EcommerceEvent => {
  const ecommerceItems = useGetEcommerceItems();

  return {
    ecommerce: { items: ecommerceItems }
  };
};

export const useGetAddPaymentInfoEvent = (): EcommerceEvent => {
  const { promoCode } = useSessionContext();
  const { currency, totalPrice } = useGetEcommercePrice();
  const ecommerceItems = useGetEcommerceItems();

  return {
    ecommerce: {
      currency,
      value: totalPrice,
      coupon: promoCode,
      items: ecommerceItems
    }
  };
};

export const useGetPurchaseEvent = (): EcommerceEvent => {
  const { promoCode, orderNumber, paymentMethod } = useSessionContext();

  const { currency, tax, totalPrice } = useGetEcommercePrice();
  const ecommerceItems = useGetEcommerceItems();

  return {
    ecommerce: {
      transaction_id: orderNumber,
      affiliation: 'V2 checkout',
      value: totalPrice,
      tax,
      shipping: 0,
      currency,
      coupon: promoCode,
      payment_type: paymentMethod,
      items: ecommerceItems
    }
  };
};
