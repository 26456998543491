import React from 'react';
import {
  BankIcon,
  CreditCardIcon,
  TickIcon,
  SelectPill,
  SelectPillGroup
} from '@myob/myob-widgets';
import { PAYMENT_METHOD } from '../../../constants';

type PaymentModeProps = {
  paymentMethod: PAYMENT_METHOD;
  handlePaymentOptionChange: (paymentMethod: PAYMENT_METHOD) => void;
};
const PaymentSelector = (
  { paymentMethod, handlePaymentOptionChange, checked }: PaymentModeProps & { checked: boolean }
) => (
  <SelectPill
    prefixAccessory={checked && (paymentMethod === PAYMENT_METHOD.CREDIT ? <CreditCardIcon/> : <BankIcon/>)}
    suffixAccessory={checked && <TickIcon/>}
    label={paymentMethod}
    name={paymentMethod}
    value={paymentMethod}
    onChange={() => handlePaymentOptionChange(paymentMethod)}
    checked={checked}
  />
);

export const PaymentMode: React.FC<PaymentModeProps> = (
  { paymentMethod, handlePaymentOptionChange }
) => (
  <SelectPillGroup label='Payment method' selectionType='single'>
    <PaymentSelector
      paymentMethod={PAYMENT_METHOD.CREDIT}
      handlePaymentOptionChange={handlePaymentOptionChange}
      checked={paymentMethod === PAYMENT_METHOD.CREDIT}
    />
    <PaymentSelector
      paymentMethod={PAYMENT_METHOD.DEBIT}
      handlePaymentOptionChange={handlePaymentOptionChange}
      checked={paymentMethod === PAYMENT_METHOD.DEBIT}
    />
  </SelectPillGroup>
);
