// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/man-with-cap-1660.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackgroundImage_backgroundImage__T0Hy3 {
  width: 100%;
  background-size: cover;
  height: 100vh;
  background-image: none;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1024px) {
  .BackgroundImage_backgroundImage__T0Hy3 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: -300px 0;
  }
}
@media screen and (max-width: 578px) {
  .BackgroundImage_backgroundImage__T0Hy3 {
    background-image: none;
  }
}
@media screen and (orientation: portrait) {
  .BackgroundImage_backgroundImage__T0Hy3 {
    background-image: none;
  }
}
@media screen and (min-width: 1280px) {
  .BackgroundImage_backgroundImage__T0Hy3 {
    background-position: -340px 0;
  }
}
@media screen and (min-width: 1536px) {
  .BackgroundImage_backgroundImage__T0Hy3 {
    background-position: -290px 0;
  }
}
@media screen and (min-width: 1792px) {
  .BackgroundImage_backgroundImage__T0Hy3 {
    background-position: -230px 0;
  }
}
@media screen and (min-width: 2048px) {
  .BackgroundImage_backgroundImage__T0Hy3 {
    background-position: -260px 0;
  }
}
@media screen and (min-width: 2304px) {
  .BackgroundImage_backgroundImage__T0Hy3 {
    background-position: -240px 0;
  }
}
@media screen and (max-height: 624px) {
  .BackgroundImage_backgroundImage__T0Hy3 {
    background-image: none;
  }
}
@media screen and (max-width: 578px) {
  .BackgroundImage_backgroundImage__T0Hy3 {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/generic/background/BackgroundImage.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,4BAAA;AAAF;AACE;EANF;IAOI,yDAAA;IACA,6BAAA;EAEF;AACF;AADE;EAVF;IAWI,sBAAA;EAIF;AACF;AAHE;EAbF;IAcI,sBAAA;EAMF;AACF;AALE;EAhBF;IAiBI,6BAAA;EAQF;AACF;AAPE;EAnBF;IAoBI,6BAAA;EAUF;AACF;AATE;EAtBF;IAuBI,6BAAA;EAYF;AACF;AAXE;EAzBF;IA0BI,6BAAA;EAcF;AACF;AAbE;EA5BF;IA6BI,6BAAA;EAgBF;AACF;AAbE;EAjCF;IAkCI,sBAAA;EAgBF;AACF;AAdE;EArCF;IAsCI,aAAA;EAiBF;AACF","sourcesContent":["@import 'assets/scss/global';\n.backgroundImage {\n  width: 100%;\n  background-size: cover;\n  height: 100vh;\n  background-image: none;\n  background-repeat: no-repeat;\n  @media screen and (min-width: $px-1024) {\n    background-image: url('../../../assets/images/man-with-cap-1660.png');\n    background-position: -300px 0;\n  }\n  @media screen and (max-width: $mobilePortraitWidth) {\n    background-image: none;\n  }\n  @media screen and (orientation: portrait) {\n    background-image: none;\n  }\n  @media screen and (min-width: $px-1280) {\n    background-position: -340px 0;\n  }\n  @media screen and (min-width: $px-1536) {\n    background-position: -290px 0;\n  }\n  @media screen and (min-width: $px-1792) {\n    background-position: -230px 0;\n  }\n  @media screen and (min-width: $px-2048) {\n    background-position: -260px 0;\n  }\n  @media screen and (min-width: $px-2304) {\n    background-position: -240px 0;\n  }\n\n  // Height\n  @media screen and (max-height: $px-624) {\n    background-image: none;\n  }\n\n  @media screen and (max-width: $mobilePortraitWidth) {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundImage": `BackgroundImage_backgroundImage__T0Hy3`
};
export default ___CSS_LOADER_EXPORT___;
