import React, {
  createContext, useContext, useCallback, useEffect, useState, ReactNode
} from 'react';
import {
  Auth0Client,
  RedirectLoginOptions,
  LogoutOptions,
  GetTokenSilentlyOptions,
  User
} from '@auth0/auth0-spa-js';
import config from '../../Config';
import { ClientInformation } from '../../helpers/orderDataHelper/types';

export type Auth0Props = {
  children: ReactNode;
  audience?: string;
  redirectUri?: string;
}

export type UserType = {
  email: string;
  [key: string]: string;
};

export type AppStateType = {
  clientInformation: ClientInformation
  productId: string;
  promoCode: string;
}

type Auth0ContextType = {
  user?: User;
  isAuthenticated?: boolean;
  isLoading: boolean;
  error?: Error;
  appState?: AppStateType;
  logout: (options?: LogoutOptions) => void;
  loginWithRedirect: (options?: RedirectLoginOptions) => void;
  loginAfterLogout: (options?: RedirectLoginOptions) => void;
  getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
}

export const Auth0Context = createContext<Auth0ContextType>({
  isLoading: true,
  logout: () => '',
  loginWithRedirect: () => '',
  loginAfterLogout: () => '',
  getTokenSilently: () => Promise.resolve('')
});

export const useAuth0 = (): Auth0ContextType => useContext(Auth0Context);

const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

const hasAuthParams = (searchParams = window.location.search): boolean =>
  (CODE_RE.test(searchParams) && STATE_RE.test(searchParams)) ||
  ERROR_RE.test(searchParams);

const onRedirectCallback = (): void => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  );
};

const Auth0: React.FC<Auth0Props> = ({
  children,
  audience,
  redirectUri
}) => {
  const [client] = useState(new Auth0Client({
    domain: config.MYOB_ID_DOMAIN,
    clientId: config.CHECKOUT_AUTH0_CLIENT_ID,
    authorizationParams: {
      audience,
      redirect_uri: redirectUri
    }
  }));

  const [user, setUser] = useState<User>();
  const [appState, setAppState] = useState();
  const [isLoading, setIsloading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState();
  useEffect(() => {
    if (hasAuthParams()) {
      client.handleRedirectCallback().then((result) => {
        onRedirectCallback();
        client.getUser().then((userInfo) => {
          setUser(userInfo);
          setIsAuthenticated(!!userInfo);
          setIsloading(false);
        });
        setAppState(result.appState);
      }).catch((err) => setError(err));
    } else {
      setIsloading(false);
    }
  }, [client]);

  const logout = useCallback((options?: LogoutOptions) => client.logout(options), [client]);

  const getTokenSilently = useCallback(
    (options?: GetTokenSilentlyOptions) => client.getTokenSilently(options),
    [client]
  );

  const loginWithRedirect = useCallback(
    (options?: RedirectLoginOptions) => client.loginWithRedirect(options),
    [client]
  );

  const loginAfterLogout = useCallback(
    (options?: RedirectLoginOptions): Promise<void> =>
      client.loginWithRedirect({
        ...options,
        openUrl: (url) => client.logout({ logoutParams: { returnTo: url } })
      }).catch((err) => setError(err)),
    [client]
  );

  return (
    <Auth0Context.Provider value={{
      user,
      appState,
      isAuthenticated,
      isLoading,
      error,
      logout,
      loginWithRedirect,
      loginAfterLogout,
      getTokenSilently
    }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0;

