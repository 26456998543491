// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-terms-checkbox {
  font-size: 14px;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 578px) {
  .payment-terms-checkbox {
    width: 29em;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/paymentTerms/PaymentTerms.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,qBAAA;AADF;AAEE;EAHF;IAII,WAAA;EACF;AACF","sourcesContent":["@import 'assets/scss/global';\n\n.payment-terms-checkbox {\n  font-size: 14px;\n  margin-bottom: 2.5rem;\n  @media screen and (min-width: 578px) {\n    width: 29em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
