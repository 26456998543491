import Cookies from 'js-cookie';
import config from 'Config';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';

export const useDuplicateTrialCheck = (
  currentContactIdentityId: string
): boolean => {
  const { productId } = useSessionContext();
  const savedContactIdentityId = Cookies.get(`checkout-trial-${productId}`)?.toLowerCase();
  return savedContactIdentityId === currentContactIdentityId;
};

export const useSetDuplicateTrial = (
  trialOrderSuccess: boolean,
  contactIdentityId: string | undefined
): void => {
  const { productId } = useSessionContext();
  if (trialOrderSuccess && productId && contactIdentityId) {
    Cookies.set(`checkout-trial-${productId}`, contactIdentityId, {
      domain: config.BUY_BASE_HOST,
      secure: true,
      sameSite: 'Strict',
      expires: 7
    });
  }
};
