import { useEffect } from 'react';

import { GTM_ANALYTICS_CONSTANTS } from '../../constants';
import {
  pushToCheckoutDatalayer,
  useGetAddPaymentInfoEvent,
  useGetBeginCheckoutAndAccountSetupEvent,
  useGetPurchaseEvent
} from './useGTMAnalyticsHelper';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { IdentifyParams, UseCommonEventProps } from './types';

const clearEcommerce = () => {
  pushToCheckoutDatalayer({ ecommerce: null });
};

export const useCommonEvent = ({
  ecommerceEvent, identifyParams, eventName, shouldPushEvent
}:UseCommonEventProps):void => {
  useEffect(() => {
    if (shouldPushEvent && ecommerceEvent.ecommerce && ecommerceEvent.ecommerce.items.length > 0) {
      // eslint-disable-next-line no-param-reassign
      ecommerceEvent.event = eventName;
      clearEcommerce();
      pushToCheckoutDatalayer({ ...identifyParams, ...ecommerceEvent });
    }
  }, [JSON.stringify(ecommerceEvent), JSON.stringify(identifyParams), shouldPushEvent]);
};

export const useBeginCheckoutEvent = (
  identifyParams?: IdentifyParams,
  isEmailChecked = true,
  eventName = GTM_ANALYTICS_CONSTANTS.BEGIN_CHECKOUT
): void => {
  const ecommerceEvent = useGetBeginCheckoutAndAccountSetupEvent();
  useCommonEvent({
    shouldPushEvent: isEmailChecked,
    eventName,
    ecommerceEvent,
    identifyParams
  });
};

export const useAccountSetupEvent = (
  identifyParams?: IdentifyParams,
  shouldPushEvent = true,
  eventName = GTM_ANALYTICS_CONSTANTS.ACCOUNT_SETUP
): void => {
  const ecommerceEvent = useGetBeginCheckoutAndAccountSetupEvent();
  useCommonEvent({
    shouldPushEvent,
    eventName,
    ecommerceEvent,
    identifyParams
  });
};

export const useAddPaymentInfoEvent = (eventName = GTM_ANALYTICS_CONSTANTS.ADD_PAYMENT_INFO):void => {
  const ecommerceEvent = useGetAddPaymentInfoEvent();
  useCommonEvent({
    eventName,
    ecommerceEvent,
    shouldPushEvent: true
  });
};

export const usePurchaseEvent = (eventName = GTM_ANALYTICS_CONSTANTS.PURCHASE): void => {
  const { orderNumber } = useSessionContext();
  const ecommerceEvent = useGetPurchaseEvent();

  useCommonEvent({
    shouldPushEvent: !!orderNumber,
    eventName,
    ecommerceEvent
  });
};

export const useEnhancedPurchaseEvent = (email?: string): void => {
  const { orderNumber } = useSessionContext();
  const ecommerceEvent = useGetPurchaseEvent();
  const eventName = GTM_ANALYTICS_CONSTANTS.ENHANCED_PURCHASE;

  if (ecommerceEvent?.ecommerce) {
    ecommerceEvent.ecommerce.email = email;
  }

  useCommonEvent({
    shouldPushEvent: !!orderNumber,
    eventName,
    ecommerceEvent
  });
};
