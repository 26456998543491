import React from 'react';
import { Box } from '@myob/myob-widgets';
import { Image } from 'components/image/Image';
import soloImage from 'assets/svg/solo-logo.svg';
import { ProgressBar } from 'components/generic/progressBar/ProgressBar';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import style from './ContentPanel.module.scss';
import { FEELIX_THEMES } from '../../../constants';

type RightPanelProps = {
  faded: boolean;
  contentClassName?: string;
  showProductCard?: boolean;
}
export const ContentPanel: React.FC<RightPanelProps> = ({
  children, faded, contentClassName, showProductCard = true
}) => {
  const { flowStep, theme } = useSessionContext();
  return (
    <Box className={[style.contentPanel, {
      [style.contentPanelFaded]: faded,
      [style.lavenderColorMobile]: flowStep === -1,
      [style.default]: theme !== FEELIX_THEMES.SOLO,
      [style.spacerForProductCard]: showProductCard
    }]}
    >
      { theme !== FEELIX_THEMES.SOLO && children}
      { theme === FEELIX_THEMES.SOLO && <Box
        className={[style.contentPanelContent, {
          [contentClassName || '']: Boolean(contentClassName)
        }]}
      >
        <Image
          alt='solo'
          src={soloImage}
          className={`${style.logo} ${flowStep === -1 && style.hideLogo}`}
        />
        <ProgressBar/>
        {children}
      </Box> }
    </Box>
  );
};
