import React from 'react';
import {
  Button, MYOBLogo, PageHead, Spinner
} from '@myob/myob-widgets';

import { Main } from 'common/main/Main';
import { useDuplicateTrialCheck } from 'hooks/useDuplicateTrialCheck/useDuplicateTrialCheck';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { Redirect, useHistory } from 'react-router';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { get } from 'lodash';
import config from 'Config';

import './DuplicateTrial.scss';
import FeatureList from 'components/FeatureList/FeatureList';
import { ANALYTICS_ID } from '../../constants';
import { FeatureContainer } from '../featureContainer/FeatureContainer';

const DuplicateTrial: React.FC = () => {
  const history = useHistory();
  const {
    user, isLoading, isAuthenticated: isAuth0Authenticated
  } = useAuth0();
  const email = get(user, 'email');
  const { region } = useSessionContext();
  const isAuthenticated = isAuth0Authenticated && !isLoading;
  const contactIdentityId = user?.['https://id.myob.com/user_id'].toLowerCase();
  const isDuplicateTrial = useDuplicateTrialCheck(contactIdentityId);

  if (isLoading) {
    return (
      <Main>
        <FeatureContainer>
          <FeatureList />
        </FeatureContainer>
        <div className={'feature-box-right'}>
          <div style={{ width: '6.25em', marginBottom: '1em' }}>
            <MYOBLogo />
          </div>
          <Spinner data-testid='spinner' size='medium' />
        </div>
      </Main>
    );
  }

  if (!isAuthenticated) {
    return <Redirect to='/error' />;
  }

  if (isAuthenticated && !isDuplicateTrial) {
    return (
      <Redirect to={`/${region.toLowerCase()}/trial/confirmation${history.location.search}`} />
    );
  }

  return (
    <Main>
      <FeatureContainer>
        <FeatureList />
      </FeatureContainer>
      <div className={'feature-box-right'}>
        <div style={{ width: '6.25em', marginBottom: '1em' }}>
          <MYOBLogo />
        </div>
        <PageHead title={'You already have a trial!'} />
        <p>Looks like you have an existing trial that you can jump into now</p>
        <div className='duplicate-email'>
          <span>Email: {email}</span>
        </div>
        <div className='duplicate-buttons'>
          <Button
            id={ANALYTICS_ID.BUTTON_RESUME_TRIAL}
            className='resume-trial-button analytics-track-click'
            type='primary'
            onClick={() => { window.location.href = `${config.PRODUCT_URL}`; }}
          >
            Resume trial
          </Button>
          <Button
            id={ANALYTICS_ID.BUTTON_CREATE_NEW_TRIAL}
            className='analytics-track-click'
            type='secondary'
            onClick={() => history.push(`/${region.toLowerCase()}/trial/confirmation`)}
          >
            Create new trial
          </Button>
        </div>
      </div>
    </Main>
  );
};

export default DuplicateTrial;
