// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 578px) {
  .ProductHeading_modern_heading__n9Nv4 {
    padding-top: 1rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

@media screen and (max-width: 578px) {
  .ProductHeading_modern_rowOne__OwNey {
    margin-top: 0.6rem;
  }
}

.ProductHeading_modern_rowTwo__1KWuj {
  font-size: 3.2rem;
}
@media screen and (max-width: 578px) {
  .ProductHeading_modern_rowTwo__1KWuj {
    padding-top: 0rem;
    font-size: 2.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/generic/productCard/productHeader/ProductHeading.modern.module.scss"],"names":[],"mappings":"AAGE;EADF;IAEI,iBAAA;IACA,oBAAA;IACA,qBAAA;EADF;AACF;;AAKE;EADF;IAEI,kBAAA;EADF;AACF;;AAIA;EACE,iBAAA;AADF;AAEE;EAFF;IAGI,iBAAA;IACA,iBAAA;EACF;AACF","sourcesContent":["@import 'assets/scss/global';\n\n.heading {\n  @media screen and (max-width: $mobilePortraitWidth) {\n    padding-top: 1rem;\n    padding-left: 2.4rem;\n    padding-right: 2.4rem;\n  }\n}\n\n.rowOne {\n  @media screen and (max-width: $mobilePortraitWidth) {\n    margin-top: .6rem;\n  }\n}\n\n.rowTwo {\n  font-size: 3.2rem;\n  @media screen and (max-width: $mobilePortraitWidth) {\n    padding-top: 0rem;\n    font-size: 2.5rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `ProductHeading_modern_heading__n9Nv4`,
	"rowOne": `ProductHeading_modern_rowOne__OwNey`,
	"rowTwo": `ProductHeading_modern_rowTwo__1KWuj`
};
export default ___CSS_LOADER_EXPORT___;
