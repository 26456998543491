export const initializeSegment = (onReady) => {
  if (!window.analytics) {
    window.analytics = [];
  }
  const { analytics } = window;
  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console && window.console.error && window.console.error('Segment snippet included twice.');
    } else {
      analytics.invoked = true;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware'
      ];
      analytics.factory = (e) => (...args) => {
        const t = Array.prototype.slice.call(args);
        t.unshift(e);
        analytics.push(t);
        return analytics;
      };
      for (const methodKey of analytics.methods) {
        // eslint-disable-next-line security/detect-object-injection
        analytics[methodKey] = analytics.factory(methodKey);
      }
      analytics.load = (key, e) => {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
        const n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(t, n);
        // eslint-disable-next-line no-underscore-dangle
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.13.2'; // TODO we should update this file according to latest segment doc
      if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
        analytics.ready(onReady);
      } else {
        // by pass on local
        onReady();
      }
      analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY);
    }
  }
};
