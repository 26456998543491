import { Box, SuccessIcon, Text } from '@myob/myob-widgets';
import React from 'react';

export const FeatureList = (props: { items: string[] }): React.JSX.Element => (
  <ul
    className='feature-list-container'
    data-testid='feature-list-container'
  >
    {props.items.map((feature) => (
      <li key={feature} data-testid='feature-item'>
        <Box
          alignItems='flex-start'
          display='flex'
          gap='2xs'
          color='neutralStrongest'
          marginBottom='md'
        >
          <div className='success-icon'>
            <SuccessIcon color='currentColor' size='lg'/>
          </div>
          <Text as='span' tone='neutralStrongest' fontSize='md'>
            {feature}
          </Text>
        </Box>
      </li>
    ))}
  </ul>);
