// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-of-use-checkbox {
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 578px) {
  .terms-of-use-checkbox {
    width: 32em;
    margin-bottom: 2.5rem;
  }
}

.send-promotion-checkbox {
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 578px) {
  .send-promotion-checkbox {
    width: 32em;
    margin-bottom: 2.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/termsOfUse/TermsOfUse.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,gBAAA;EACA,qBAAA;AADF;AAEE;EAJF;IAKI,WAAA;IACA,qBAAA;EACF;AACF;;AAEC;EACE,eAAA;EACA,gBAAA;EACA,qBAAA;AACH;AAAG;EAJF;IAKI,WAAA;IACA,qBAAA;EAGH;AACF","sourcesContent":["@import 'assets/scss/global';\n\n.terms-of-use-checkbox {\n  font-size: 14px;\n  margin-top: 1rem;\n  margin-bottom: 2.5rem;\n  @media screen and (min-width: 578px) {\n    width: 32em;\n    margin-bottom: 2.5rem;\n  }\n}\n\n .send-promotion-checkbox {\n   font-size: 14px;\n   margin-top: 1rem;\n   margin-bottom: 2.5rem;\n   @media screen and (min-width: 578px) {\n     width: 32em;\n     margin-bottom: 2.5rem;\n   }\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
