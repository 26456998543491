import { useAuth0 } from 'hooks/Auth0/Auth0';
import { useEffect, useState } from 'react';

export const useGetAccessToken = (): { accessToken: string } => {
  const [accessToken, setAccessToken] = useState('');
  const { isLoading, getTokenSilently } = useAuth0();
  useEffect(() => {
    (async (): Promise<void> => {
      if (!isLoading) {
        const accessToken = await getTokenSilently();
        setAccessToken(accessToken);
      }
    })();
  }, [getTokenSilently, isLoading]);
  return { accessToken };
};
