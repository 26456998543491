import React from 'react';
import {
  Box, Text, Heading
} from '@myob/myob-widgets';
import { Image } from 'components/image/Image';
import ArrowIcon from 'assets/svg/chevron_up_icon.svg';
import style from './ProductHeading.module.scss';
import modernStyle from './ProductHeading.modern.module.scss';
import { getStyleProps } from './ProductHeading.style';
import { FEELIX_THEMES } from '../../../../constants';

type ProductHeadingProps = {
  heading: string;
  handleClick?: () => void;
  isFooterOpen: boolean;
  theme: string;
}

export const ProductHeading = (
  {
    heading,
    handleClick,
    isFooterOpen,
    theme
  }: ProductHeadingProps
) => {
  const styleProps = getStyleProps(theme);
  return (
    <Box
      {...styleProps.container}
      onClick={isFooterOpen ? undefined : handleClick}
      className={[
        {
          [modernStyle.heading]: theme === FEELIX_THEMES.MODERN
        }
      ]}
    >
      <Image
        className={isFooterOpen ? style.chevronOpen : style.chevron}
        src={ArrowIcon}
        alt='arrow'
        onClick={handleClick}
      />
      <Text
        className={[
          style.rowOne,
          { [modernStyle.rowOne]: theme === FEELIX_THEMES.MODERN }
        ]}
        data-content-desktop='Summary of your plan'
        data-content-mobile={heading}
        {...styleProps.rowOne}
      />
      <Heading
        className={[style.rowTwo, {
          [modernStyle.rowTwo]: theme === FEELIX_THEMES.MODERN
        }]}
        data-content-desktop={heading}
        data-content-mobile='Summary of your plan'
        {...styleProps.rowTwo}
      />
    </Box>
  );
};
