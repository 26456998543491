import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import QRCode from 'qrcode';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import style from './Confirmation.solo.module.scss';

export const SoloQRCode: React.FC = () => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const { region } = useSessionContext();
  const url = `${baseUrl}/${region.toLowerCase()}/solo/mobile-app`;

  const ref = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    QRCode.toCanvas(ref.current, url, { width: 240, margin: 5.5 });
  }, [ref, ref.current]);
  if (isMobile) return null;
  return (
    <canvas ref={ref} className={style.downloadQRCodeImage}></canvas>
  );
};
