import { CreditCardDetail, DirectDebitDetail } from 'components/paymentPage/types';
import { PaymentProfileRequest } from 'hooks/useCheckout/types';
import { PAYMENT_METHOD } from '../../constants';
import { PaymentProfile } from './types';

const buildPayloadCreditCard = (
  creditCardDetail: CreditCardDetail
) => {
  const creditCardPayment = {
    name_on_card: creditCardDetail.nameOnCard,
    card_token: creditCardDetail.number,
    last_four_digits: creditCardDetail.maskedNumber.slice(-4),
    type: creditCardDetail.cardType,
    expiry_month: creditCardDetail.expiryMonth,
    expiry_year: creditCardDetail.expiryYear
  };

  return {
    credit_card: creditCardPayment
  };
};

const buildPayloadDirectDebit = (
  directDebitDetail: DirectDebitDetail
) => {
  const {
    accountName,
    bank,
    branch,
    account,
    suffix,
    bsb,
    accountNumber
  } = directDebitDetail;

  let bankCode = bsb;
  if (bank && branch) {
    bankCode = bank + branch;
  }

  let bankNumber = accountNumber;
  if (account && suffix) {
    bankNumber = account + suffix;
  }

  return {
    direct_debit: {
      bsb: bankCode,
      bank_name: accountName,
      account_number: bankNumber
    }
  };
};
export const usePaymentProfile =
  ({
    creditCardDetail,
    directDebitDetail,
    paymentMethod,
    isAnnualPrice
  }: PaymentProfileRequest): PaymentProfile => ({
    payment_schedule: isAnnualPrice ? 'Annual' : 'Monthly',
    payment_method: paymentMethod,
    ...(paymentMethod === PAYMENT_METHOD.CREDIT ?
      buildPayloadCreditCard(creditCardDetail as CreditCardDetail) :
      buildPayloadDirectDebit(directDebitDetail as DirectDebitDetail)
    )
  }) as PaymentProfile;
