import _ from 'lodash';
import { OrderItem } from '../hooks/useGetProduct/types';
import { ANNUAL_BASE_DISCOUNT, REGIONS } from '../constants';

export const formatToTwoDecimalPlaces = (value: number) : string => (
  value.toFixed(2)
);

export const getListPrice = (product: OrderItem, isAnnual: boolean, region?: string): string => {
  const basePrice = region === REGIONS.AUSTRALIA ? product.listPrice : product.listPriceWithoutTax;
  const listPrice = isAnnual ? basePrice * 12 * ANNUAL_BASE_DISCOUNT : basePrice;
  return formatToTwoDecimalPlaces(listPrice);
};

export const getDiscountPrice = (product: OrderItem, isAnnual: boolean, region?: string): string => {
  const baseDiscountPrice = region === REGIONS.AUSTRALIA ? product.totalPrice : product.priceWithoutTax;
  const discountPrice = isAnnual ? baseDiscountPrice * 12 : baseDiscountPrice;
  return formatToTwoDecimalPlaces(discountPrice);
};

export const getItemPrice = (item : OrderItem, isTaxExcluded: boolean) : number => {
  const originalPrice = isTaxExcluded ? item.listPriceWithoutTax : item.listPrice;
  return originalPrice - Number(item.discountAmount.toFixed(2));
};

export const getProductTotalPrice = (productItems: OrderItem[], isAnnual: boolean): string => {
  const totalPrice = _.sumBy(productItems, (item) => getItemPrice(item, false));
  const productTotalPrice = isAnnual ? totalPrice * 12 : totalPrice;
  return formatToTwoDecimalPlaces(productTotalPrice);
};

export const getIsDiscounted = (productItems: OrderItem[], isAnnual: boolean, region?: string): boolean => {
  const roundedNormalPrice = Math.round(Number(getListPrice(productItems[0], false, region)));
  const roundedDiscountedPrice = Math.round(Number(getDiscountPrice(productItems[0], false, region)));
  return roundedDiscountedPrice < roundedNormalPrice;
};

