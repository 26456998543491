// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeatureList_solo_featureListContainer__cOUJf {
  list-style-type: none;
  margin: 2rem 0;
}
.FeatureList_solo_featureListContainer__cOUJf .FeatureList_solo_successIcon__9Ddz0 {
  height: 24px;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/generic/productCard/featureList/FeatureList.solo.module.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,cAAA;AADF;AAGE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AADJ","sourcesContent":["@import 'assets/scss/global';\n\n.featureListContainer {\n  list-style-type: none;\n  margin: 2rem 0;\n\n  .successIcon {\n    height: 24px;\n    display: flex;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"featureListContainer": `FeatureList_solo_featureListContainer__cOUJf`,
	"successIcon": `FeatureList_solo_successIcon__9Ddz0`
};
export default ___CSS_LOADER_EXPORT___;
