import 'moment-timezone';
import { useEffect } from 'react';

import { ANALYTICS_CONSTANTS } from '../../constants';
import {
  pushToDataLayer,
  useGetCheckoutStartedEvent, useGetPurchaseEvent, useGetOrderCompletedEvent
} from './useAnalyticsHelper';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';

export const useCheckoutStarted = (isReady: boolean): void => {
  const ecommerceEvent = useGetCheckoutStartedEvent();
  useEffect(() => {
    if (ecommerceEvent.products.length > 0 && isReady) {
      pushToDataLayer({ ...ecommerceEvent, event: ANALYTICS_CONSTANTS.CHECKOUT_STARTED_EVENT });
    }
  }, [JSON.stringify(ecommerceEvent), isReady]);
};

export const useSendPurchaseEventAndOrderCompletedEvent = (): void => {
  const { orderNumber } = useSessionContext();
  const purchaseEvent = useGetPurchaseEvent();
  const orderCompletedEvent = useGetOrderCompletedEvent();

  useEffect(() => {
    if (orderNumber &&
      orderCompletedEvent.products.length > 0 &&
      purchaseEvent.ecommerce.purchase.products.length > 0) {
      pushToDataLayer(orderCompletedEvent);
      pushToDataLayer(purchaseEvent);
    }
  }, [
    JSON.stringify(orderCompletedEvent),
    JSON.stringify(purchaseEvent),
    orderNumber
  ]);
};

export const useContentViewed = (
  { elementId, enabled = true }: { elementId: string, enabled?: boolean }
): void => {
  useEffect(() => {
    if (enabled) {
      const contentViewedEvent = {
        event: ANALYTICS_CONSTANTS.CONTENT_VIEWED,
        label: '',
        elementId,
        elementClasses: ''
      };
      pushToDataLayer(contentViewedEvent);
    }
  }, [enabled, elementId]);
};

export const useFormSubmit = ({ elementId }: { elementId: string }): void => {
  useEffect(() => {
    const recordFormSubmitEvent = {
      event: ANALYTICS_CONSTANTS.FORM_SUBMIT,
      elementId
    };
    pushToDataLayer(recordFormSubmitEvent);
  }, [elementId]);
};

export const useFormCompleted = (
  { elementId, isError }: { elementId: string, isError: boolean }
): void => {
  useEffect(() => {
    const recordFormCompletedEvent = {
      event: isError ?
        ANALYTICS_CONSTANTS.FORM_FAIL :
        ANALYTICS_CONSTANTS.FORM_SUCCESS,
      elementId,
      ...isError && { errorText: '' }
    };

    pushToDataLayer(recordFormCompletedEvent);
  }, [elementId, isError]);
};
