import React from 'react';
import { Input, Box } from '@myob/myob-widgets';
import { Button } from 'components/button/Button';
import { trimFocusEventInputValue } from 'helpers/trim/trim';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import TermsOfUse from 'components/termsOfUse/TermsOfUse';
import { ANALYTICS_ID, REGULAR_EXPRESSIONS, SUBSCRIPTION_TYPE } from '../../../constants';
import soloStyles from './AccountDetailsForm.solo.module.scss';

export const AccountDetailsForm: React.FC<{
  emailValue: string,
  disabled: boolean,
  handleSubmit: () => void,
  submitButtonText: string,
  isTermsCheck: boolean,
  termsErrorMessage: string,
  setTerms: () => void,
  sendPromotionsCheck: boolean,
  setSendPromotions: () => void,
  register: any,
  errors: any
}
> = ({
  emailValue, disabled, handleSubmit, submitButtonText, isTermsCheck,
  termsErrorMessage, setTerms, sendPromotionsCheck, setSendPromotions, register, errors
}) => {
  const { subscriptionType, productId } = useSessionContext();

  const { getLabels } = useContentContext();
  const givenNameLabel = getLabels(ELEMENTS.ACCOUNT_DETAILS_INPUT_GIVEN_NAME);
  const familyNameLabel = getLabels(ELEMENTS.ACCOUNT_DETAILS_INPUT_FAMILY_NAME);
  const phoneLabel = getLabels(ELEMENTS.ACCOUNT_DETAILS_INPUT_PHONE);

  return (
    <Box className={soloStyles.container}>
      <Input
        id={ANALYTICS_ID.INPUT_EMAIL}
        name='email'
        label='Email*'
        reference={register({ required: true })}
        value={emailValue}
        disabled
      />
      <Input
        id={ANALYTICS_ID.INPUT_FIRST_NAME}
        name='firstName'
        label={`${givenNameLabel}*`}
        onBlur={trimFocusEventInputValue}
        reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.FIRST_NAME })}
        errorMessage={
          errors.firstName && (errors.firstName.type === 'required' ?
          `${givenNameLabel} is required` : `${givenNameLabel} is invalid`)}
      />
      <Input
        id={ANALYTICS_ID.INPUT_LAST_NAME}
        name='lastName'
        label={`${familyNameLabel}*`}
        onBlur={trimFocusEventInputValue}
        reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.LAST_NAME })}
        errorMessage={
          errors.lastName && (errors.lastName.type === 'required' ?
          `${familyNameLabel} is required` : `${familyNameLabel} is invalid`)}
      />
      <Input
        id={ANALYTICS_ID.INPUT_PHONE_NUMBER}
        name='phoneNumber'
        label={`${phoneLabel}*`}
        onBlur={trimFocusEventInputValue}
        inputMode='tel'
        reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.PHONE })}
        errorMessage={
          errors.phoneNumber && (errors.phoneNumber.type === 'required' ?
          `${phoneLabel} is required` : `${phoneLabel} is invalid`)}
      />
      <Input
        id={ANALYTICS_ID.INPUT_BUSINESS_NAME}
        name='businessName'
        label='Business name*'
        onBlur={trimFocusEventInputValue}
        reference={register({
          required: true,
          validate: (value: string) => REGULAR_EXPRESSIONS.BUSINESS_NAME.test(value) &&
            REGULAR_EXPRESSIONS.NO_ONLY_SPACE.test(value)
        })}
        errorMessage={errors.businessName && (errors.businessName.type === 'required' ?
          'Business name is required' : 'Business name is invalid')}
      />
      {subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE &&
      <TermsOfUse
        isTermsCheck={isTermsCheck} termsErrorMessage={termsErrorMessage} setTerms={setTerms}
        sendPromotionsCheck={sendPromotionsCheck} setSendPromotions={setSendPromotions}
        productId={productId}
      />
      }
      <Button
        id={ANALYTICS_ID.BUTTON_LOGIN}
        onClick={handleSubmit}
        disabled={disabled}
      >
        {submitButtonText}
      </Button>
    </Box>
  );
};

