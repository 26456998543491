// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PricingMolecule_pricingMolecule__dKTeb .PricingMolecule_dollarSign__TdlCF {
  font-size: 5rem;
}
.PricingMolecule_pricingMolecule__dKTeb .PricingMolecule_dollarAmount__KzmVk {
  font-size: 5rem;
}
.PricingMolecule_pricingMolecule__dKTeb .PricingMolecule_centsAmount__BMBxT {
  font-size: 1.5rem;
}
.PricingMolecule_pricingMolecule__dKTeb .PricingMolecule_denominator__q5gNE {
  font-size: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/generic/productCard/priceMolecule/PricingMolecule.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,eAAA;AAHJ","sourcesContent":[".pricingMolecule {\n  .dollarSign {\n    font-size: 5rem;\n  }\n\n  .dollarAmount {\n    font-size: 5rem;\n  }\n\n  .centsAmount {\n    font-size: 1.5rem;\n  }\n\n  .denominator {\n    font-size: 2rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pricingMolecule": `PricingMolecule_pricingMolecule__dKTeb`,
	"dollarSign": `PricingMolecule_dollarSign__TdlCF`,
	"dollarAmount": `PricingMolecule_dollarAmount__KzmVk`,
	"centsAmount": `PricingMolecule_centsAmount__BMBxT`,
	"denominator": `PricingMolecule_denominator__q5gNE`
};
export default ___CSS_LOADER_EXPORT___;
