import { REGIONS } from '../constants';

export const formatNZPhoneNumber = (phoneNumber: string): string => {
  let formatted = phoneNumber.replace(/\s/g, '');
  const firstChar = phoneNumber.charAt(0);
  if (firstChar === '0') {
    formatted = `+64${formatted.substring(1)}`;
  }
  return formatted;
};

export const formatAUPhoneNumber = (phoneNumber: string): string => phoneNumber.replace(/\s/g, '');

const formatPhoneNumber = (phoneNumber: string, region: string): string => (
  region === REGIONS.AUSTRALIA ? formatAUPhoneNumber(phoneNumber) : formatNZPhoneNumber(phoneNumber)
);

export default formatPhoneNumber;
