// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductFooter_productFooter__EMLU8 {
  width: 100%;
}
@media screen and (max-width: 578px) {
  .ProductFooter_productFooter__EMLU8 {
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  .ProductFooter_productFooter__EMLU8.ProductFooter_productFooterOpen__No9VE {
    max-height: 60vh;
    height: auto;
    overflow: auto;
  }
}

.ProductFooter_termsAndConditions__fP3qI {
  font-size: 1.1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/generic/productCard/productFooter/ProductFooter.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;AAAF;AAEE;EAHF;IAII,aAAA;IACA,gCAAA;EACF;EACE;IACE,gBAAA;IACA,YAAA;IACA,cAAA;EACJ;AACF;;AAGA;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;AAAF","sourcesContent":["@import 'assets/scss/global';\n.productFooter {\n  width: 100%;\n\n  @media screen and (max-width: $mobilePortraitWidth) {\n    max-height: 0;\n    transition: max-height 0.5s ease;\n\n    &.productFooterOpen {\n      max-height: 60vh;\n      height: auto;\n      overflow: auto;\n    }\n  }\n}\n\n.termsAndConditions {\n  font-size: 1.1rem;\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productFooter": `ProductFooter_productFooter__EMLU8`,
	"productFooterOpen": `ProductFooter_productFooterOpen__No9VE`,
	"termsAndConditions": `ProductFooter_termsAndConditions__fP3qI`
};
export default ___CSS_LOADER_EXPORT___;
