// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PricingMolecule_solo_pricingMolecule__-8yp9 .PricingMolecule_solo_leftInteger__p4UIF {
  font-size: 5rem;
  font-weight: 900;
  line-height: 1;
}
.PricingMolecule_solo_pricingMolecule__-8yp9 .PricingMolecule_solo_centsAmount__jhpNE {
  font-size: 1.8rem;
  font-weight: 700;
}
.PricingMolecule_solo_pricingMolecule__-8yp9 .PricingMolecule_solo_denominator__BE4Fk {
  font-size: 1.6rem;
  font-weight: 400;
  color: #45464A;
}`, "",{"version":3,"sources":["webpack://./src/components/generic/productCard/priceMolecule/PricingMolecule.solo.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAAJ;AAGE;EACE,iBAAA;EACA,gBAAA;AADJ;AAIE;EACE,iBAAA;EACA,gBAAA;EACA,cAAA;AAFJ","sourcesContent":[".pricingMolecule {\n  .leftInteger {\n    font-size: 5rem;\n    font-weight: 900;\n    line-height: 1;\n  }\n\n  .centsAmount {\n    font-size: 1.8rem;\n    font-weight: 700;\n  }\n\n  .denominator {\n    font-size: 1.6rem;\n    font-weight: 400;\n    color: #45464A;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pricingMolecule": `PricingMolecule_solo_pricingMolecule__-8yp9`,
	"leftInteger": `PricingMolecule_solo_leftInteger__p4UIF`,
	"centsAmount": `PricingMolecule_solo_centsAmount__jhpNE`,
	"denominator": `PricingMolecule_solo_denominator__BE4Fk`
};
export default ___CSS_LOADER_EXPORT___;
