import React from 'react';
import { Box } from '@myob/myob-widgets';
import { get } from 'lodash';
import { getIsDiscounted, getListPrice } from 'utils/pricingUtils';
import { useProductData } from 'hooks/useProductData/useProductData';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { PricingMolecule } from '../priceMolecule/PricingMolecule';
import soloStyles from './PricingCard.solo.module.scss';
import defaultStyles from './PricingCard.module.scss';
import { FEELIX_THEMES } from '../../../../constants';
import { ModulePrice } from '../modulePrice/ModulePrice';
import { OfferText } from '../offerText/OfferText';

type PriceProps = {
  price: string;
  currency: string;
  paymentSchedule: string;
  isExcludeTax: boolean;
}
type PricingCardProps = {
  pricing: PriceProps,
  theme: string
}

const getPriceDenominator = (paymentSchedule: string, isExcludeTax: boolean) => {
  const taxDescription = isExcludeTax ? '(+ GST)' : '(inc. GST)';
  return `/${paymentSchedule} ${taxDescription}`;
};

const getStyle = (theme: string) => {
  if (theme === FEELIX_THEMES.SOLO) {
    return soloStyles;
  }
  return defaultStyles;
};
export const PricingCard: React.FC<PricingCardProps> = ({
  pricing: {
    price, paymentSchedule, isExcludeTax, currency
  }, theme
}) => {
  const style = getStyle(theme);
  const { region } = useSessionContext();
  const { productData, moduleProductData } = useProductData();
  const productItems = get(productData, 'items');
  const roundedNormalPrice = Math.round(Number(getListPrice(productItems[0], false, region)));
  const isDiscounted = getIsDiscounted(productItems, false, region);
  return (
    <Box className={style.pricingCard}>
      <OfferText text={get(productItems[0], 'deal.promotionName')} theme={theme} />
      <PricingMolecule
        price={price}
        denominator={getPriceDenominator(paymentSchedule, isExcludeTax)}
        currency={currency}
        theme={theme}
      />
      {isDiscounted && (
        <span
          className={style.normalPriceText}
        >
          Normally ${roundedNormalPrice.toString()}/month
        </span>
      )}
      <ModulePrice
        productData={productData}
        moduleProductData={moduleProductData}
      />
    </Box>
  );
};
