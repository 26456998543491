import React, { memo } from 'react';
import { get } from 'lodash';
import { Checkbox } from '@myob/myob-widgets';
import { LEGAL_LINKS } from '../../constants';
import './PaymentTerms.scss';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';

const getLink =
    (region: string): string | undefined => get(LEGAL_LINKS, region).DIRECT_DEBIT_TERMS_AND_CONDITIONS;

type PaymentTermsProps = {
  isChecked: boolean;
  errorMessage: string;
  setTerms: () => void;
}

const PaymentTerms: React.FC<PaymentTermsProps> = ({
  isChecked, errorMessage, setTerms
}) => {
  const { region } = useSessionContext();
  return (
    <Checkbox
      data-testid='debit-terms-of-use'
      label={
        <div>
          <span data-testid={'t&c'}>
            I have sufficient authority to make this direct debit request on behalf of the owner(s) of this
            bank account and have read and agreed to the
            <a
              rel='noopener noreferrer'
              target='_blank'
              href={getLink(region)}
            >
              {' '} Direct Debit Terms & Conditions.
            </a>
          </span>
        </div>
      }
      name='debit-terms-of-use'
      className='payment-terms-checkbox'
      onChange={() => setTerms() }
      checked={isChecked}
      errorMessage={errorMessage}
    />
  );
};

export default memo(PaymentTerms);
