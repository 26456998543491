// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.duplicate-email {
  margin-bottom: 2rem;
}

.duplicate-buttons {
  display: flex;
  flex-direction: column;
}

.resume-trial-button {
  margin-bottom: 1rem;
}

@media screen and (min-width: 578px) {
  .duplicate-buttons {
    flex-direction: row;
  }
  .resume-trial-button {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/duplicateTrial/DuplicateTrial.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE;IACE,mBAAA;EADF;EAIA;IACE,gBAAA;IACA,kBAAA;EAFF;AACF","sourcesContent":["@import 'assets/scss/global';\n\n.duplicate-email {\n  margin-bottom: 2rem;\n}\n\n.duplicate-buttons {\n  display: flex;\n  flex-direction: column;\n}\n\n.resume-trial-button {\n  margin-bottom: 1rem;\n}\n\n@media screen and (min-width: 578px) {\n  .duplicate-buttons {\n    flex-direction: row;\n  }\n\n  .resume-trial-button {\n    margin-bottom: 0;\n    margin-right: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
