import React, { memo } from 'react';
import { Stepper } from '@myob/myob-widgets';
import { STEPPER_TYPE } from '../../constants';
import './Stepper.scss';

type SteppersProps = {
  activeStepNumber: string;
  firstType: string;
  secondType: string;
  thirdType?: string;
  stepperType?: string;
}

const Steppers: React.FC<SteppersProps> = ({
  activeStepNumber,
  firstType,
  secondType,
  thirdType,
  stepperType
}) => {
  const style = activeStepNumber === '3' ? 'final-step' : 'stepper';
  const trialSteps = [
    { number: '1', title: 'Account setup', type: firstType },
    { number: '2', title: 'Confirmation', type: secondType }
  ];
  const purchaseSteps = [
    { number: '1', title: 'Account setup', type: firstType },
    { number: '2', title: 'Payment details', type: secondType },
    { number: '3', title: 'Confirmation', type: thirdType }
  ];

  const getSteps = () => {
    switch (stepperType) {
      case STEPPER_TYPE.PURCHASE: return purchaseSteps;
      case STEPPER_TYPE.TRIAL: return trialSteps;
      default: return purchaseSteps;
    }
  };

  return (
    <Stepper
      className={style}
      activeStepNumber={activeStepNumber}
      steps={getSteps()}
    />);
};

export default memo(Steppers);
