// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_main__3wVqW {
  display: flex;
  height: 100vh;
}

.Main_main__3wVqW {
  display: flex;
  flex: 1 1;
  height: 100vh;
}

@media screen and (max-width: 578px) {
  .Main_main__3wVqW {
    flex-direction: column-reverse;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/generic/main/Main.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,aAAA;AAAF;;AAEA;EACE,aAAA;EACA,SAAA;EACA,aAAA;AACF;;AAEA;EACE;IACE,8BAAA;EACF;AACF","sourcesContent":["@import 'assets/scss/global';\n.main {\n  display: flex;\n  height: 100vh;\n}\n.main {\n  display: flex;\n  flex: 1;\n  height: 100vh;\n}\n\n@media screen and (max-width: $mobilePortraitWidth) {\n  .main {\n    flex-direction: column-reverse;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Main_main__3wVqW`
};
export default ___CSS_LOADER_EXPORT___;
