// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 578px) {
  .feature-box-tick {
    margin-right: 0.3rem;
  }
  .feature-box-image-container {
    overflow: hidden;
    text-align: center;
    width: 100%;
  }
  .feature-box-image-container .feature-box-image {
    max-width: 450px;
    width: 100%;
  }
}
@media screen and (max-width: 578px) {
  .feature-box-image-container {
    display: none;
  }
  .feature-box-content-container {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/FeatureList/FeatureList.scss"],"names":[],"mappings":"AAAA;EACE;IACE,oBAAA;EACF;EAEA;IACE,gBAAA;IACA,kBAAA;IACA,WAAA;EAAF;EAEE;IACE,gBAAA;IACA,WAAA;EAAJ;AACF;AAIA;EACE;IACE,aAAA;EAFF;EAIA;IACE,aAAA;EAFF;AACF","sourcesContent":["@media screen and (min-width: 578px) {\n  .feature-box-tick {\n    margin-right: 0.3rem;\n  }\n\n  .feature-box-image-container {\n    overflow: hidden;\n    text-align: center;\n    width: 100%;\n\n    .feature-box-image {\n      max-width: 450px;\n      width: 100%;\n    }\n  }\n}\n\n@media screen and (max-width: 578px) {\n  .feature-box-image-container {\n    display: none;\n  }\n  .feature-box-content-container{\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
