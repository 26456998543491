import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuth0 } from 'hooks/Auth0/Auth0';

import { useSendPurchaseEventAndOrderCompletedEvent, useContentViewed } from 'hooks/Analytics/useAnalytics';
import FeatureList from 'components/FeatureList/FeatureList';
import { get } from 'lodash';
import { useTrialOrderingCall } from 'hooks/useTrialOrderingCall/useTrialOrderingCall';

import '../Confirmation.scss';

import { useEnhancedPurchaseEvent, usePurchaseEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { usePurchaseEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { useNinetailedCheckoutEvent } from 'hooks/useNinetailedAnalytics/useNinetailedAnalytics';
import { ANALYTICS_ID, GTM_ANALYTICS_CONSTANTS, NINETAILED_ANALYTICS_CONSTANTS } from '../../../constants';
import { useSendEventToSegmentForPurchase } from '../useSendEventToSegmentForPurchase';
import ConfirmationPage from '../ConfirmationPage';
import laptopImage from '../../../assets/images/computer-screen.png';

function useTrackTrialConfirmationPage(email: string | undefined) {
  useContentViewed({
    elementId: ANALYTICS_ID.CONTENT_VIEWED_TRIAL_CONFIRMATION
  });
  useSendPurchaseEventAndOrderCompletedEvent();
  usePurchaseEventGA4();
  usePurchaseEvent(GTM_ANALYTICS_CONSTANTS.PURCHASE_SEGMENT);
  useSendEventToSegmentForPurchase();
  useEnhancedPurchaseEvent(email);
  // Ninetailed track event for checkout with payment
  useNinetailedCheckoutEvent(NINETAILED_ANALYTICS_CONSTANTS.PURCHASE);
}

export const TrialConfirmation: React.FC = () => {
  const { user, isLoading: isAuth0Loading, isAuthenticated } = useAuth0();
  const { isLoading: isOrderingLoading, isError } = useTrialOrderingCall();
  const email = get(user, 'email');

  useTrackTrialConfirmationPage(email);

  const isLoading = isAuth0Loading || isOrderingLoading;
  if (!isLoading && (!isAuthenticated || isError)) {
    return <Redirect to={'/error'}/>;
  }

  return (
    <ConfirmationPage
      featureList={<FeatureList />}
      isLoading={isLoading}
      pageTitle='What happens next?'
      mainDescription={
        <>
          <img className='confirmation-page-main-image' src={laptopImage} alt='desktop'/>
          <div className='confirmation-page-main-image-desc'>
            <p><b>Access your product</b></p>
            <p>Learn the ropes with the help of your own personalised setup activities</p>
          </div>
        </>
      }
    />
  );
};
