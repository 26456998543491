import React, { useState } from 'react';
import {
  Button, Input, MYOBLogo, PageHead
} from '@myob/myob-widgets';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { TermsConditions } from 'components/terms/Terms';
import { AccountDetailsForm as GenericAccountDetailsForm } from 'components/generic';
import { useSendEventToSegment } from 'hooks/useSendEventToSegment/useSendEventToSegment';
import { useFeatureBoxClassName } from 'hooks/useNewMobileDesignHooks/useNewMobileDesignHooks';
import { RecaptchaDescription } from 'components/recaptchaDescription/RecaptchaDescription';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import Steppers from 'components/stepper/Steppers';
import { trimFocusEventInputValue } from 'helpers/trim/trim';
import TermsOfUse from 'components/termsOfUse/TermsOfUse';
import { useAccountSetupEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { useAddShippingInfoEventGA4, useCheckoutSignInEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import {
  ANALYTICS_ID,
  COOKIE_AJS_ANONYMOUS_ID,
  COOKIE_AJS_USER_ID,
  COOKIE_MYOB_VISITOR_ID, FEELIX_THEMES,
  GTM_ANALYTICS_CONSTANTS,
  REGULAR_EXPRESSIONS,
  STEP_PATH, STEPPER_TYPE,
  SUBSCRIPTION_TYPE
} from '../../constants';
import config from '../../Config';
import '../../app/App.scss';

export const AccountDetailsForm: React.FC<{
  pageHeaderText: string
  submitButtonText: string
  isNewUser: boolean
  emailValue: string
}> = ({
  pageHeaderText, submitButtonText, isNewUser, emailValue
}) => {
  const {
    region, promoCode, subscriptionType, productId, promotionEndDate, isAnnualPrice, theme
  } = useSessionContext();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  if (promoCode) {
    params.set('pc', promoCode);
  }

  const [isTermsCheck, setIsTermsCheck] = React.useState(false);
  const [termsErrorMessage, setTermsErrorMessage] = React.useState('');
  const [sendPromotionsCheck, setSendPromotionsCheck] = React.useState(true);
  const [disabled, setDisableValue] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const setTerms = () => {
    setIsTermsCheck(!isTermsCheck);
    setTermsErrorMessage('');
  };
  const setSendPromotions = () => {
    setSendPromotionsCheck(!sendPromotionsCheck);
  };

  const featureBoxClassName = useFeatureBoxClassName();

  const {
    register,
    getValues,
    handleSubmit,
    errors
  } = useForm({ mode: 'onTouched' });

  const { loginAfterLogout } = useAuth0();

  const appState = () => ({ clientInformation: { ...getValues(), sendPromotions: sendPromotionsCheck, isNewUser } });

  const getRedirectUrl = () => {
    const queryString = params.toString() ? `?${params.toString()}` : '';

    if (subscriptionType === SUBSCRIPTION_TYPE.TRIAL) {
      if (isNewUser) {
        return `${config.BUY_BASE_URL}/${region.toLowerCase()}${STEP_PATH.TRIAL.CONFIRMATION}${queryString}`;
      }
      return `${config.BUY_BASE_URL}/${region.toLowerCase()}${STEP_PATH.TRIAL.DUPLICATE}${queryString}`;
    }

    return `${config.BUY_BASE_URL}/${region.toLowerCase()}` +
        `${isAnnualPrice ? STEP_PATH.SUBSCRIBE.PAYMENT_ANNUAL : STEP_PATH.SUBSCRIBE.PAYMENT}${queryString}`;
  };

  const postDirectDebitEvent = () => {
    if (subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE && !isTermsCheck) {
      setTermsErrorMessage("Please confirm you've acknowledged the terms of use");
    } else {
      handleSubmit(onSubmit)();
    }
  };
  const {
    businessName, phoneNumber, lastName, firstName
  } = getValues();
  const identifyParamsOld = {
    last_name: lastName,
    first_name: firstName,
    business_name: businessName,
    phone_number: phoneNumber,
    terms_and_conditions: isTermsCheck,
    communication_preference: sendPromotionsCheck
  };

  const anonymousId = Cookies.get(COOKIE_AJS_ANONYMOUS_ID);
  const myobVisitorId = Cookies.get(COOKIE_MYOB_VISITOR_ID);
  const userId = Cookies.get(COOKIE_AJS_USER_ID);

  const identifyParams = {
    userId,
    anonymousId,
    traits: {
      ...identifyParamsOld,
      myobVisitorId
    }
  };

  const trackParams = {
    event: GTM_ANALYTICS_CONSTANTS.ACCOUNT_SETUP_SEGMENT,
    userId,
    anonymousId,
    properties: {
      myobVisitorId
    }
  };

  useAccountSetupEvent();
  useAccountSetupEvent(identifyParamsOld, isSubmit, GTM_ANALYTICS_CONSTANTS.ACCOUNT_SETUP_SEGMENT);
  useAddShippingInfoEventGA4(isNewUser);
  useCheckoutSignInEventGA4(isNewUser, (isSubmit && subscriptionType === SUBSCRIPTION_TYPE.TRIAL));
  useSendEventToSegment({
    shouldSendSegmentEvent: isSubmit,
    identifyParams,
    trackParams
  });
  const onSubmit = async () => {
    setIsSubmit(true);
    setDisableValue(true);
    const redirectUrl = getRedirectUrl();
    if (isNewUser) {
      const email = getValues('email');
      loginAfterLogout({
        appState: appState(),
        authorizationParams: {
          screen_hint: 'signup',
          ui_hint: 'essentials',
          login_hint: email,
          redirect_uri: redirectUrl
        }
      });
    } else {
      loginAfterLogout({
        appState: appState(),
        authorizationParams: {
          login_hint: emailValue,
          redirect_uri: redirectUrl
        }
      });
    }
  };

  if (theme === FEELIX_THEMES.SOLO) {
    return (
      <GenericAccountDetailsForm
        emailValue={emailValue}
        disabled={disabled}
        handleSubmit={postDirectDebitEvent}
        // todo: get rid of submitButtonText prop
        submitButtonText={submitButtonText}
        isTermsCheck={isTermsCheck}
        termsErrorMessage={termsErrorMessage}
        setTerms={setTerms}
        sendPromotionsCheck={sendPromotionsCheck}
        setSendPromotions={setSendPromotions}
        register={register}
        errors={errors}
      />
    );
  }

  return (
    <div data-testid='account-details-form' className={featureBoxClassName}>
      {subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE &&
      <Steppers activeStepNumber='1' firstType='' secondType='incomplete'
        thirdType='incomplete' stepperType={STEPPER_TYPE.PURCHASE} data-testid='step1'
      />}
      <div style={{ width: '6.25em', marginBottom: '1em' }}>
        <MYOBLogo />
      </div>
      <PageHead title={pageHeaderText}/>
      <div style={{
        width: '20em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}
      >
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_EMAIL}
          name='email'
          label='Email*'
          value={emailValue}
          reference={register({ required: true })}
          disabled
        />
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_FIRST_NAME}
          name='firstName'
          label='First name*'
          onBlur={trimFocusEventInputValue}
          reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.FIRST_NAME })}
          errorMessage={errors.firstName && (errors.firstName.type === 'required' ?
                'First name is required' : 'First name is invalid')}
        />
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_LAST_NAME}
          name='lastName'
          label='Last name*'
          onBlur={trimFocusEventInputValue}
          reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.LAST_NAME })}
          errorMessage={errors.lastName && (errors.lastName.type === 'required' ?
                'Last name is required' : 'Last name is invalid')}
        />
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_PHONE_NUMBER}
          name='phoneNumber'
          label='Phone number*'
          onBlur={trimFocusEventInputValue}
          inputMode='tel'
          reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.PHONE })}
          errorMessage={ errors.phoneNumber && (errors.phoneNumber.type === 'required' ?
                'Phone number is required' : 'Phone number is invalid')}
        />
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_BUSINESS_NAME}
          name='businessName'
          label='Business name*'
          onBlur={trimFocusEventInputValue}
          reference={register({
                required: true,
                validate: (value) =>
                  REGULAR_EXPRESSIONS.BUSINESS_NAME.test(value) && REGULAR_EXPRESSIONS.NO_ONLY_SPACE.test(value)
              })}
          errorMessage={ errors.businessName && (errors.businessName.type === 'required' ?
                'Business name is required' : 'Business name is invalid')}
        />
        {subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE &&
        <TermsOfUse
          isTermsCheck={isTermsCheck} termsErrorMessage={termsErrorMessage} setTerms={setTerms}
          sendPromotionsCheck={sendPromotionsCheck} setSendPromotions={setSendPromotions}
          productId={productId}
        />
        }
        <Button
          id={ANALYTICS_ID.BUTTON_LOGIN}
          type='primary'
          onClick={postDirectDebitEvent}
          disabled={disabled}
        >
          {submitButtonText}
        </Button>
        {promoCode && promotionEndDate && <TermsConditions className='terms'/> }
      </div>
      <RecaptchaDescription />
    </div>
  );
};
