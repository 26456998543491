import React from 'react';
import {
  isIOS, isAndroid
} from 'react-device-detect';
import {
  Box, Heading, Text
} from '@myob/myob-widgets';
import { Image } from 'components/image/Image';
import desktopImage from 'assets/images/solo-confirmation-desktop.png';
import mobileImage from 'assets/svg/solo_confirmation_mobile.svg';
import appleAppIconImage from 'assets/svg/apple_app_icon.svg';
import googleAppIconImage from 'assets/svg/google_app_icon.svg';
import { ContentPanel } from '../../contentPanel/ContentPanel';
import { SoloQRCode } from './SoloQRCode';
import { getSoloAppLinks, ANALYTICS_ID } from '../../../../constants';
import style from './Confirmation.solo.module.scss';

const getButtons = () => {
  if (isIOS) {
    return ['apple'];
  } if (isAndroid) {
    return ['google'];
  }
  return ['apple', 'google'];
};

const storeLinks: Record<string, any> = {
  apple: {
    id: ANALYTICS_ID.LINK_SOLO_APPLE_APP_STORE,
    link: getSoloAppLinks().APPLE_APP_STORE,
    icon: appleAppIconImage,
    alt: 'Solo App Store Link'
  },
  google: {
    id: ANALYTICS_ID.LINK_SOLO_GOOGLE_PLAY_STORE,
    link: getSoloAppLinks().GOOGLE_PLAY_STORE,
    icon: googleAppIconImage,
    alt: 'Solo Google Play Store Link'
  }
};

const getStoreLinks = () => {
  const buttons = getButtons();
  return (
    <>
      {buttons.map((button) => (
        <a
          id={storeLinks[button].id}
          key={button}
          href={storeLinks[button].link}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Image
            src={storeLinks[button].icon}
            alt={storeLinks[button].alt}
          />
        </a>
      ))}
    </>
  );
};

export const SoloConfirmation: React.FC = () => (
  <Box className={style.confirmationContainer}>
    <Box className={style.confirmationContent}>
      <Box
        className={style.desktopImage}
      >
        <Image
          src={desktopImage}
          alt='solo_confirmation_desktop_image'
        />
      </Box>
      <ContentPanel
        faded={false}
        showProductCard={false}
        contentClassName={style.content}
      >
        <Box
          className={style.mobileImage}
        >
          <Image
            src={mobileImage}
            alt='solo_confirmation_mobile_image'
          />
        </Box>
        <Heading level='1'>Welcome to Solo!</Heading>
        <Text fontSize='xl' marginBottom='2xs'>
          You made it. Great move. <br/>We’re thrilled to have you here!
        </Text>
        <Heading
          level='3'
          paddingTop='md'
          paddingBottom='md'
        >
          Download the Solo app to get started
        </Heading>
        <SoloQRCode />
        <Box
          className={style.buttonContainer}
          marginTop='sm'
          display='flex'
        >
          {getStoreLinks()}
        </Box>
        <Text
          fontWeight='medium'
          marginTop='xl'
          marginBottom='xs'
          className={style.desktopText}
        >
          On a desktop? Just scan the QR code with your phone to grab the Solo app from the app store.
        </Text>
        <Text marginTop='xs' fontWeight='inherit'>
          We’ve sent you an email to confirm your purchase. Let the fun begin!
        </Text>
      </ContentPanel>
    </Box>
  </Box>
);
