import React, { useEffect, useState, memo } from 'react';
import { Button } from '@myob/myob-widgets';
import './ProgressButton.scss';

export type ProgressButtonProps = {
  duration: number;
  startText?: string;
  endText: string;
  className?: string;
  href: string;
  id?: string;
};

export function useProgress(duration: number, endText: string): { inProgress: boolean } {
  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setInProgress(false);
    }, duration);
  }, [duration, endText]);

  return { inProgress };
}

const ProgressButton: React.FC<ProgressButtonProps> = (props) => {
  const {
    startText, endText, duration, className, href, id
  } = props;

  const { inProgress } = useProgress(duration, endText);

  return (
    <a
      style={{ textDecoration: 'none' }}
      {...(!inProgress ? { href } : {})}
      target='_blank'
      rel='noreferrer'
    >
      <Button
        id={id}
        disabled={inProgress}
        className={`progress-button ${className}`}
        style={{ animationDuration: `${duration}ms` }}
      >
        {inProgress ? startText : endText}
      </Button>
    </a>
  );
};

ProgressButton.defaultProps = {
  duration: 5000
};

export default memo(ProgressButton);
