// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-button {
  background: linear-gradient(to right, #00aa65 50%, #95D1B7 50%);
  background-origin: border-box;
  background-size: 200% 100%;
  background-position: left bottom;
  animation: background linear;
  animation-duration: 15s;
  width: 100%;
}
.progress-button a, .progress-button span {
  color: white;
}
@keyframes background {
  from {
    background-position: right bottom;
  }
  to {
    background-position: left bottom;
  }
}

@media screen and (min-width: 578px) {
  .progress-button {
    width: 250px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/confirmation/progressButton/ProgressButton.scss"],"names":[],"mappings":"AAGA;EACE,+DAAA;EACA,6BAAA;EACA,0BAAA;EACA,gCAAA;EACA,4BAAA;EACA,uBAAA;EAWA,WAAA;AAZF;AAGE;EACE,YAAA;AADJ;AAIE;EACE;IAAO,iCAAA;EADT;EAEE;IAAK,gCAAA;EACP;AACF;;AAIA;EACE;IACE,YAAA;EADF;AACF","sourcesContent":["$golf: #00aa65;\n$golf-light: #95D1B7;\n\n.progress-button {\n  background: linear-gradient(to right, $golf 50%, $golf-light 50%);\n  background-origin: border-box;\n  background-size: 200% 100%;\n  background-position: left bottom;\n  animation: background linear;\n  animation-duration: 15s;\n\n  a, span {\n    color: white;\n  }\n\n  @keyframes background {\n    from { background-position: right bottom; }\n    to { background-position: left bottom; }\n  }\n\n  width: 100%;\n}\n\n@media screen and (min-width: 578px) {\n  .progress-button {\n    width: 250px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
