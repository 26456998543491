import React, { ReactNode } from 'react';
import { TickIcon } from '@myob/myob-widgets';
import { COLOURS_AS_HEX } from '../../constants';

type TickListProps = {
  items: ReactNode[];
  styles?: React.CSSProperties;
  tickColor?: string;
};

export const TickList: React.FC<TickListProps> = ({
  items, tickColor, styles, ...rest
}) => (
  <ul {...rest} style={styles}>
    {items?.map((item, index) => (
      <li key={index} style={{ display: 'flex', position: 'relative', marginBottom: '1rem' }}>
        <TickIcon className='feature-box-tick' data-testid='tick' />
        <small className='tick-list-content' style={{ marginLeft: '0.4rem' }}>
          {item}
        </small>
      </li>
    ))}
  </ul>
);

TickList.defaultProps = {
  tickColor: COLOURS_AS_HEX.Golf
};
