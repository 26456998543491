import React, { ComponentProps, FC } from 'react';

export type ComponentWithProps = FC | [FC, Record<string, any>];

export const combineComponents = (...componentsWithProps: ComponentWithProps[]): FC =>
  componentsWithProps
    .map((component) => {
      const [Component, props] = Array.isArray(component) ? component : [component, {}];
      return ({ children }: ComponentProps<FC>) => <Component {...props}> {children} </Component>;
    })
    .reduce(
      (AccumulatedComponents, CurrentComponent) => ({ children }: ComponentProps<FC>): JSX.Element => (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      ),
      ({ children }) => <>{children} </>
    );
