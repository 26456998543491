import React from 'react';
import { Box, Text } from '@myob/myob-widgets';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';

export const PricingMolecule:
(props: { price: string, denominator: string, currency: string}) => React.JSX.Element =
    (props: { price: string, denominator: string, currency:string}) => {
      const [dollars, cents] = props.price.split('.');
      const { promoCode, promotionEndDate } = useSessionContext();

      return (
        <Box
          className='pricing-molecule'
          data-testid='pricing-molecule'
          display='flex'
        >
          <Text
            className='dollar-sign'
            as='span'
            fontWeight='bold'
            lineHeight='clear'
          >
            $
          </Text>
          <Text
            className='dollar-amount'
            as='span'
            fontWeight='bold'
            lineHeight='clear'
          >
            {dollars}
          </Text>
          <Box
            display='flex'
            flexDirection='column'
          >
            <Text
              className='cents-amount'
              as='span'
              fontWeight='bold'
              fontSize='lg'
            >
              .{cents} {props.currency} {promoCode && promotionEndDate ? '^' : ''}
            </Text>
            <Text
              className='denominator'
              as='span'
              tone='neutralStrongest'
            >
              {props.denominator}
            </Text>
          </Box>
        </Box>
      );
    };
