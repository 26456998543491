import { isMobile } from 'react-device-detect';
import useFeatureToggle from 'hooks/useFeatureToggle/useFeatureToggle';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { FEATURE_TOGGLE, SUBSCRIPTION_TYPE } from '../../constants';

export const useFeatureBoxClassName = (): string => {
  const { subscriptionType } = useSessionContext();
  const isTrial = subscriptionType === SUBSCRIPTION_TYPE.TRIAL;
  const useGeneric = useFeatureToggle(FEATURE_TOGGLE.GENERIC_COMPONENTS_TOGGLE);
  const useFullHeightContent = isMobile && !isTrial && !useGeneric;
  return `feature-box-right${useFullHeightContent ? ' full-height' : ''}`;
};
