// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoPanel_infoPanel__Md-Cx {
  display: flex;
  flex: 1 1;
  background: #F2E8FD;
  align-items: center;
  justify-content: right;
  flex-direction: row-reverse;
}
.InfoPanel_infoPanel__Md-Cx.InfoPanel_default__6Rpkr {
  background: #FFFFFF;
  justify-content: center;
}

.InfoPanel_spacer__FMkIH {
  flex: 0.2 1;
}

@media screen and (max-width: 578px) {
  .InfoPanel_infoPanel__Md-Cx {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex: 0 1;
    position: absolute;
    z-index: 99999;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/generic/infoPanel/InfoPanel.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,2BAAA;AAAF;AACE;EACE,mBAAA;EACA,uBAAA;AACJ;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE;IACE,uBAAA;IACA,2BAAA;IACA,mBAAA;IACA,SAAA;IACA,kBAAA;IACA,cAAA;IACA,WAAA;EAAF;AACF","sourcesContent":["@import 'assets/scss/global';\n.infoPanel {\n  display: flex;\n  flex: 1;\n  background: #F2E8FD;\n  align-items: center;\n  justify-content: right;\n  flex-direction: row-reverse;\n  &.default {\n    background: #FFFFFF;\n    justify-content: center;\n  }\n}\n\n.spacer {\n  flex: .2;\n}\n\n@media screen and (max-width: $mobilePortraitWidth) {\n  .infoPanel {\n    align-items: flex-start;\n    justify-content: flex-start;\n    flex-direction: row;\n    flex: 0;\n    position: absolute;\n    z-index: 99999;\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoPanel": `InfoPanel_infoPanel__Md-Cx`,
	"default": `InfoPanel_default__6Rpkr`,
	"spacer": `InfoPanel_spacer__FMkIH`
};
export default ___CSS_LOADER_EXPORT___;
