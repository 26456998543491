// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductCard_productCard__rnzWT {
  width: 393px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  transition: transform 0.7s ease;
}
@media screen and (max-width: 1792px) {
  .ProductCard_productCard__rnzWT {
    transform: scale(1);
  }
}
@media screen and (max-width: 1536px) {
  .ProductCard_productCard__rnzWT {
    transform: scale(0.9);
  }
}
@media screen and (max-width: 1280px) {
  .ProductCard_productCard__rnzWT {
    transform: scale(0.85);
  }
}
@media screen and (max-width: 1024px) {
  .ProductCard_productCard__rnzWT {
    transform: scale(0.75);
  }
}
@media screen and (max-width: 578px) {
  .ProductCard_productCard__rnzWT {
    transform: scale(1);
    width: 100%;
    position: fixed;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/generic/productCard/ProductCard.module.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,kBAAA;EACA,+BAAA;AAAF;AAGE;EAXF;IAYI,mBAAA;EAAF;AACF;AAEE;EAfF;IAgBI,qBAAA;EACF;AACF;AACE;EAnBF;IAoBI,sBAAA;EAEF;AACF;AAAE;EAvBF;IAwBI,sBAAA;EAGF;AACF;AADE;EA3BF;IA4BI,mBAAA;IACA,WAAA;IACA,eAAA;IACA,SAAA;IACA,4BAAA;IACA,6BAAA;EAIF;AACF","sourcesContent":["@import 'assets/scss/global';\n.productCard {\n  width: 393px;\n  background: #ffffff;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n  position: relative;\n  transition: transform 0.7s ease;\n\n  // Width\n  @media screen and (max-width: $px-1792) {\n    transform: scale(1);\n  }\n\n  @media screen and (max-width: $px-1536) {\n    transform: scale(0.9);\n  }\n\n  @media screen and (max-width: $px-1280) {\n    transform: scale(0.85);\n  }\n\n  @media screen and (max-width: $px-1024) {\n    transform: scale(0.75);\n  }\n\n  @media screen and (max-width: $mobilePortraitWidth) {\n    transform: scale(1);\n    width: 100%;\n    position: fixed;\n    bottom: 0;\n    border-bottom-left-radius: 0;\n    border-bottom-right-radius: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productCard": `ProductCard_productCard__rnzWT`
};
export default ___CSS_LOADER_EXPORT___;
