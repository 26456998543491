import React from 'react';
import { Tooltip } from '@myob/myob-widgets';
import { OrderItem } from '../hooks/useGetProduct/types';
import { PAYROLL_MODULE_LIMIT } from '../constants';

export type ProductBasicInfo = Pick<OrderItem, 'productId' | 'productTier'>;
export const getModuleTooltipMessage = (
  basicInfo: ProductBasicInfo,
  moduleProductPriceText: string
): React.JSX.Element => (
  <div style={{ paddingTop: '10px' }}>
    <h3 style={{ textAlign: 'left', color: 'white', margin: '0px 0px 10px' }}>
      Payroll
    </h3>
    <section style={{ textAlign: 'left', letterSpacing: '-0.15px' }}>
      With the {basicInfo.productTier} + Payroll plan
      you can pay {(PAYROLL_MODULE_LIMIT as any)[basicInfo.productId]} employees per month.
      Payroll is a pay-as-you-go feature and you&apos;ll get charged
      {moduleProductPriceText}, billed in arrears.
    </section>
  </div>);

export const getTooltip = (basicInfo: ProductBasicInfo, moduleProductPriceText: string): React.JSX.Element =>
  <Tooltip data-testid='tooltip-test' placement='bottom'>
    {getModuleTooltipMessage(basicInfo, moduleProductPriceText)}
  </Tooltip>;
