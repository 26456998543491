import { useEffect } from 'react';
import { snakeCase } from 'lodash';
import { useNinetailed } from '@ninetailed/experience.js-react';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { useGetPurchaseEvent } from '../GTM4Analytics/useGTMAnalyticsHelper';

export const useNinetailedCheckoutEvent = (eventName: string): void => {
  const { track } = useNinetailed();

  const {
    region,
    promoCode
  } = useSessionContext();

  const purchaseEvent = useGetPurchaseEvent();

  // get productId from purchaseEvent
  const productId = purchaseEvent?.items[0]?.item_id ?? '';

  // get productName from purchaseEvent
  const productName = purchaseEvent?.items[0]?.item_name ?? '';

  // get purchase price from purchaseEvent
  const purchasePrice = purchaseEvent?.items[0]?.price ?? '';

  useEffect(() => {
    if (productId && eventName) {
      let eventContent: { [key: string]: any } = {
        productId,
        purchasePriceWithoutTax: purchasePrice,
        region
      };

      if (promoCode !== undefined) {
        eventContent = {
          ...eventContent,
          promoCode
        };
      }

      // Adding checkout event with/without payment
      track(eventName, eventContent);

      // Adding generic checkout event
      track(`${eventName}_${snakeCase(productName)}`, eventContent);
    }
  }, [productId]);
};
