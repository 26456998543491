// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OfferText_text__qxu9M {
  padding: 4px 16px 4px 8px;
  margin-bottom: 2.4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/components/generic/productCard/offerText/OfferText.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,qBAAA;EACA,0BAAA;EAAA,uBAAA;EAAA,kBAAA;AACF","sourcesContent":[".text {\n  padding: 4px 16px 4px 8px;\n  margin-bottom: 2.4rem;\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `OfferText_text__qxu9M`
};
export default ___CSS_LOADER_EXPORT___;
