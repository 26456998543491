import React from 'react';
import './Main.scss';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { SUBSCRIPTION_TYPE } from '../../constants';

export const Main: React.FC<{
  children?: React.ReactNode,
}> = ({ children }) => {
  const { subscriptionType } = useSessionContext();
  const clazzName = subscriptionType === SUBSCRIPTION_TYPE.TRIAL ? 'trial-main-content' : 'main-content';
  const topBarClazzName = subscriptionType === SUBSCRIPTION_TYPE.TRIAL ? 'trial-top-bar' : 'top-bar';
  return (
    <>
      <div data-testid='top-bar' className={topBarClazzName} style={{ height: '10px' }}/>
      <div data-testid='main-content' className={clazzName}>
        {children}
      </div>
    </>
  );
};
