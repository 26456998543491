import React, {
  createContext, useContext, useMemo
} from 'react';
import { useLocation } from 'react-router-dom';
import useFeatureToggle from 'hooks/useFeatureToggle/useFeatureToggle';
import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import {
  FEATURE_TOGGLE,
  SUBSCRIPTION_TYPE
} from '../../constants';

const initialChecks = (pathname: string) => {
  const [subscriptionType, anyOther] = pathname.split('/').slice(2);
  return subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE && !anyOther;
};

export const PromoCodeFeatureContext = createContext(false);
export const usePromoBoxFeatureContext = (): boolean => useContext(PromoCodeFeatureContext);

export const PromoCodeBoxFeatureToggleProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  const featureEnabled = useFeatureToggle(FEATURE_TOGGLE.PROMO_BOX_FEATURE_TOGGLE);
  const { isSuccess, productData } = useGetProduct();
  const promoBoxEnabledForPromotion =
    isSuccess &&
    (!(productData.items[0]?.deal?.promoCode) ||
    (productData.items[0]?.deal?.isPromoCodeBoxApplicable ?? false));
  const isFeatureApplicable = useMemo(
    () =>
      featureEnabled &&
      initialChecks(pathname) &&
      promoBoxEnabledForPromotion,
    [featureEnabled, pathname, promoBoxEnabledForPromotion]
  );

  if (!isSuccess) {
    return null;
  }

  return (
    <PromoCodeFeatureContext.Provider value={isFeatureApplicable}>
      {children}
    </PromoCodeFeatureContext.Provider>
  );
};
