import React from 'react';
import { ThemeProvider as FeelixThemeProvider } from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';

export const ThemeProvider: React.FC = ({ children }) => {
  const { theme } = useSessionContext();
  return (
    <FeelixThemeProvider theme={theme}>
      {children}
    </FeelixThemeProvider>
  );
};
