// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-body {
  font-size: 1.5rem;
  margin-top: 1.6rem;
  color: #FFFFFF;
}
@media screen and (min-width: 578px) {
  .terms-body {
    font-size: 1rem;
    max-width: 90rem;
  }
}
@media screen and (max-width: 578px) {
  .terms-body {
    display: none;
  }
}
.terms-body p {
  color: #FFFFFF;
}
.terms-body p a {
  color: #FFFFFF;
  text-decoration: underline;
}
.terms-body .link {
  color: #FFFFFF;
  text-decoration: underline;
}

.terms .link {
  color: #515E6D;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/terms/Terms.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;AADF;AAGE;EALF;IAMI,eAAA;IACA,gBAAA;EAAF;AACF;AAEE;EAVF;IAWI,aAAA;EACF;AACF;AACE;EACE,cAAA;AACJ;AACI;EACE,cAAA;EACA,0BAAA;AACN;AAGE;EACE,cAAA;EACA,0BAAA;AADJ;;AAKA;EACE,cAAA;EACA,0BAAA;AAFF","sourcesContent":["@import 'assets/scss/global';\n\n.terms-body {\n  font-size: 1.5rem;\n  margin-top: 1.6rem;\n  color: #FFFFFF;\n\n  @media screen and (min-width: 578px) {\n    font-size: 1rem;\n    max-width: 90rem;\n  }\n\n  @media screen and (max-width: 578px) {\n    display: none;\n  }\n\n  p {\n    color: #FFFFFF;\n\n    a {\n      color: #FFFFFF;\n      text-decoration: underline\n    }\n  }\n\n  .link {\n    color: #FFFFFF;\n    text-decoration: underline\n  }\n}\n\n.terms .link {\n  color: #515E6D;\n  text-decoration: underline\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
