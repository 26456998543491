import React from 'react';
import { Spinner } from '@myob/myob-widgets';
import style from './LoadingOverlay.module.scss';

interface LoadingOverlayProps {
  isLoading: boolean;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isLoading }) => (
  <div className={isLoading ? style.overlay : style.hidden}>
    { isLoading && <Spinner size='large' /> }
  </div>
);
