import React from 'react';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { Main } from '../main/Main';
import { SoloConfirmation } from './solo/SoloConfirmation';
import { FEELIX_THEMES } from '../../../constants';

export const Confirmation: React.FC = () => {
  const { theme } = useSessionContext();
  return (
    <Main
      showProductCard={false}
      content={theme === FEELIX_THEMES.SOLO && <SoloConfirmation/>}
    />
  );
};
