import React from 'react';
import { Button } from '@myob/myob-widgets';

import config from 'Config';

import myobLogo from '../../assets/images/myob-logo.png';
import errorPageImageLeft from '../../assets/images/error-page-left-image.png';

import './ErrorPage.scss';

export const ErrorPage: React.FC = () => (
  <>
    <div
      data-testid='error-page-top-bar'
      className='error-page-top-bar'
      style={{ height: '10px' }}
    />
    <div className='error-page-container'>
      <div className='error-page-header'>
        <img
          className='error-page-header-myob-logo'
          src={myobLogo}
          alt='myob-logo'
        />
      </div>
      <div className='error-page-main'>
        <div className='error-page-left-container'>
          <img
            src={errorPageImageLeft}
            alt='error'
          />
        </div>
        <div className='error-page-right-container'>
          <h1>Oh no!</h1>
          <p className='error-page-right-container-tagline'>Something went wrong.</p>
          <Button>
            <a
              id='link_buy-myob_go-to-website'
              className='analytics-track-click'
              href='https://myob.com/'
            >
              Go to MYOB.com
            </a>
          </Button>

          <div className='error-page-right-container-bottom-text-container'>
            <p><b>Are you looking for something else?</b></p>
            <p>Already have an account? <a
              href={config.PRODUCT_URL}
              id='link_buy-myob_sign-in-to-product'
              className='analytics-track-click'
            >Sign in
            </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
);
