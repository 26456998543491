import React from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Spinner } from '@myob/myob-widgets';
import { useClickHandler } from 'hooks/useClickHandler/useClickHandler';
import { useProductData } from 'hooks/useProductData/useProductData';
import { ProductHeading } from './productHeader/ProductHeading';
import { ProductFooter } from './productFooter/ProductFooter';
import defaultStyle from './ProductCard.module.scss';
import modernStyle from './ProductCard.solo.module.scss';
import { getStyleProps } from './ProductCard.style';
import { FEELIX_THEMES } from '../../../constants';

type ProductCardProps = {
  theme: string;
  isFooterOpen: boolean;
  onFooterOpen: (open: boolean) => void;
}

export const ProductCard: React.FC<ProductCardProps> = (
  {
    theme,
    isFooterOpen,
    onFooterOpen
  }
) => {
  const {
    productData,
    isError,
    isLoading
  } = useProductData();

  const ref = useClickHandler((clickedOutside) => {
    if (isFooterOpen && clickedOutside) {
      onFooterOpen(false);
    }
  });

  if (isLoading) return <Spinner size='large'/>;
  if (isError) return <Redirect to='/error' />;
  return (
    <Box
      ref={ref}
      className={[
        defaultStyle.productCard,
        { [modernStyle.productCard]: theme === FEELIX_THEMES.MODERN }
      ]}
      {...getStyleProps(theme)}
    >
      <ProductHeading
        heading={productData?.items[0].displayName}
        handleClick={() => onFooterOpen(!isFooterOpen)}
        isFooterOpen={isFooterOpen}
        theme={theme}
      />
      <ProductFooter
        isFooterOpen={isFooterOpen}
        theme={theme}
      />
    </Box>
  );
};
