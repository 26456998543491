import React from 'react';
import { Box, Text } from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import style from './PricingMolecule.module.scss';
import { PricingMoleculeProps } from './types';

export const PricingMoleculeDefault: React.FC<PricingMoleculeProps> =
  ({
    price,
    denominator,
    currency
  }) => {
    const [dollars, cents] = price.split('.');
    const { promoCode, promotionEndDate } = useSessionContext();

    return (
      <Box
        className={style.pricingMolecule}
        data-testid='pricing-molecule'
        display='flex'
      >
        <Text
          className={style.dollarSign}
          as='span'
          fontWeight='bold'
          lineHeight='clear'
        >
          $
        </Text>
        <Text
          className={style.dollarAmount}
          as='span'
          fontWeight='bold'
          lineHeight='clear'
        >
          {dollars}
        </Text>
        <Box
          display='flex'
          flexDirection='column'
        >
          <Text
            className={style.centsAmount}
            as='span'
            fontWeight='bold'
            fontSize='lg'
          >
            .{cents} {currency} {promoCode && promotionEndDate ? '^' : ''}
          </Text>
          <Text
            className={style.denominator}
            as='span'
            tone='neutralStrongest'
          >
            {denominator}
          </Text>
        </Box>
      </Box>
    );
  };
