import React from 'react';
import {
  Box,
  Input,
  Heading,
  Text
} from '@myob/myob-widgets';
import { Button } from 'components/button/Button';
import { Image } from 'components/image/Image';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import backgroundImage from 'assets/svg/mobile_landing_hero.svg';
import { ANALYTICS_ID, COLOURS_AS_HEX, REGULAR_EXPRESSIONS } from '../../../constants';
import soloStyle from './EmailForm.solo.module.scss';
import { usePromoBoxFeatureContext } from '../../featureToggle/PromoCodeBoxFeatureToggle';
import { PromoCodeBox } from '../../promoCodeBox/PromoCodeBox';

type EmailFormProps = {
  handleSubmit: () => void;
  submitDisabled: boolean;
  formRegister: (data: any) => void;
  isError: boolean;
  errors: any;
}

export const EmailForm: React.FC<EmailFormProps> = (
  {
    handleSubmit,
    submitDisabled,
    formRegister,
    isError,
    errors
  }
) => {
  const { phoneNumber, productFreePeriod } = useSessionContext();
  const { getLabels } = useContentContext();
  const isPromoBoxFeatureEnabled = usePromoBoxFeatureContext();

  return (
    <Box className={soloStyle.container} data-testid='email-form'>
      <Image
        alt='background'
        src={backgroundImage}
        className={`${soloStyle.backgroundImage}`}
      />
      {!!productFreePeriod && productFreePeriod > 0 && (
      <Text fontSize='sm' fontWeight='medium' marginBottom={['clear', 'clear', '2xl', '2xl', '2xl']}>
          {getLabels(ELEMENTS.FREE_TRIAL_MESSAGE).replace('%s', productFreePeriod.toString())}
      </Text>
      )
      }
      <Heading level={1} marginBottom='xs' marginTop={['md', 'md', 'clear', 'clear', 'clear']}>
        {getLabels(ELEMENTS.EMAIL_PAGE_HEADING_ONE)}
      </Heading>
      <Text fontSize='sm' fontWeight='inherit' marginBottom='2xl'>
        {getLabels(ELEMENTS.EMAIL_PAGE_HEADING_TWO)}
      </Text>
      <Input
        data-testid='email-input'
        reference={formRegister({ required: true, pattern: REGULAR_EXPRESSIONS.EMAIL })}
        name='email'
        label='Email'
        requiredLabel='Email is required'
        containerClassName={soloStyle.inputContainer}
        className={soloStyle.input}
        errorMessage={
                  !!errors.email && (errors.email.type === 'required' ? 'Email is required' : 'Email is invalid')}
      />
      {!!isError && (
      <p style={{ marginTop: '1rem', color: COLOURS_AS_HEX.Berry }}>
        Something went wrong. If the issue persists, contact MYOB support on{' '}
        {phoneNumber.salesPhoneNumber}
      </p>
    )
      }
      { isPromoBoxFeatureEnabled && <PromoCodeBox /> }
      <Button
        id={ANALYTICS_ID.BUTTON_EXISTING_USER_CHECK}
        data-testid='email-submit'
        onClick={handleSubmit}
        disabled={submitDisabled}
      >
        {getLabels(ELEMENTS.EMAIL_SUBMIT_BUTTON)}
      </Button>
    </Box>
  );
};
