// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_soloButton__39nAj {
  min-width: 30rem;
  border-radius: 100px;
  padding: 1.4rem 2.4rem;
}
@media screen and (max-width: 578px) {
  .Button_soloButton__39nAj {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/button/Button.module.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,oBAAA;EACA,sBAAA;AAAF;AAEE;EALF;IAMI,WAAA;EACF;AACF","sourcesContent":["@import 'assets/scss/global';\n.soloButton {\n  min-width: 30rem;\n  border-radius: 100px;\n  padding: 1.4rem 2.4rem;\n\n  @media screen and (max-width: $mobilePortraitWidth) {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"soloButton": `Button_soloButton__39nAj`
};
export default ___CSS_LOADER_EXPORT___;
