import axios from 'helpers/axios/axios';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { areCookiesDisabled, isEmpty } from 'utils/utility';
import config from '../../Config';
import { useGetEcommerceItems } from '../GTMAnalytics/useGTMAnalyticsHelper';

type IdentifyParams = {
  userId: string | undefined,
  anonymousId?: string | undefined,
  traits: {
    email?: string,
    last_name?: string,
    first_name?: string,
    business_name?: string,
    phone_number?: string,
    terms_and_conditions?: boolean,
    communication_preference?: boolean,
    myobVisitorId: string | undefined
  }
}

type TrackParams = {
  event: string,
  userId: string | undefined,
  anonymousId?: string | undefined,
  properties: {
    currency?: string,
    value?: number,
    coupon?: string,
    myobVisitorId: string | undefined
  }
}

type SegmentEventParams = {
  identifyParams?: IdentifyParams,
  trackParams: TrackParams
}

export const useSendEventToSegment = ({
  shouldSendSegmentEvent, identifyParams, trackParams
}:{ shouldSendSegmentEvent: boolean, identifyParams?: IdentifyParams,
  trackParams: TrackParams }): void => {
  const trackEcommerceItem = {
    ...trackParams,
    properties: {
      ...trackParams.properties,
      ...useGetEcommerceItems()[0]
    }
  };

  let anonymousId = identifyParams?.anonymousId;
  const userId = identifyParams?.userId;
  const cookiesDisabled = areCookiesDisabled();
  if (isEmpty(userId) && isEmpty(anonymousId)) {
    anonymousId = uuidv4();
  }
  const segmentRequest = {
    ...(identifyParams && { identifyParams: { ...identifyParams, anonymousId } }),
    trackParams: { ...trackEcommerceItem, anonymousId },
    cookiesDisabled
  };

  useQuery(
    'segmentEvent',
    () =>
      axios.post<SegmentEventParams>(
        config.SEGMENT_EVENT_ENDPOINT,
        segmentRequest
      ),
    { enabled: shouldSendSegmentEvent, retry: false }
  );
};
