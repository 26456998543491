// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PricingCard_solo_pricingCard__O6Mum {
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #F2E8FD;
  border-radius: 2rem;
  width: 100%;
  padding: 2.4rem;
  margin: 0 0 3.2rem 0;
  gap: 8px;
}
.PricingCard_solo_pricingCard__O6Mum .PricingCard_solo_price__zyKmG {
  font-size: 3rem;
  display: flex;
}
.PricingCard_solo_pricingCard__O6Mum .PricingCard_solo_priceFirstColumn__1g-uR {
  font-size: 3rem;
  font-weight: bold;
  color: #000000;
}
.PricingCard_solo_pricingCard__O6Mum .PricingCard_solo_priceSecondColumn__q6fMN {
  font-size: 1.5rem;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.PricingCard_solo_pricingCard__O6Mum .PricingCard_solo_priceSecondColumnFirstRow__VdCTT {
  font-weight: bold;
  color: #000000;
}
.PricingCard_solo_pricingCard__O6Mum .PricingCard_solo_secondRow__irFU\\+ {
  padding: 0.25rem;
}
.PricingCard_solo_pricingCard__O6Mum .PricingCard_solo_thirdRow__TcJm9 {
  padding: 0.25rem;
}
.PricingCard_solo_pricingCard__O6Mum .PricingCard_solo_normalPriceText__4wB1V {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/generic/productCard/pricingCard/PricingCard.solo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,oBAAA;EACA,QAAA;AACF;AAAE;EACE,eAAA;EACA,aAAA;AAEJ;AACE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAEE;EACE,iBAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,cAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;AAME;EACE,eAAA;AAJJ","sourcesContent":[".pricingCard {\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  background: #F2E8FD;\n  border-radius: 2rem;\n  width: 100%;\n  padding: 2.4rem;\n  margin: 0 0 3.2rem 0;\n  gap: 8px;\n  .price {\n    font-size: 3rem;\n    display: flex;\n  }\n\n  .priceFirstColumn {\n    font-size: 3rem;\n    font-weight: bold;\n    color: #000000;\n  }\n\n  .priceSecondColumn {\n    font-size: 1.5rem;\n    color: #000000;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n  }\n\n  .priceSecondColumnFirstRow {\n    font-weight: bold;\n    color: #000000;\n  }\n\n  .secondRow {\n    padding: .25rem;\n  }\n\n  .thirdRow {\n    padding: .25rem;\n  }\n\n  .normalPriceText {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pricingCard": `PricingCard_solo_pricingCard__O6Mum`,
	"price": `PricingCard_solo_price__zyKmG`,
	"priceFirstColumn": `PricingCard_solo_priceFirstColumn__1g-uR`,
	"priceSecondColumn": `PricingCard_solo_priceSecondColumn__q6fMN`,
	"priceSecondColumnFirstRow": `PricingCard_solo_priceSecondColumnFirstRow__VdCTT`,
	"secondRow": `PricingCard_solo_secondRow__irFU+`,
	"thirdRow": `PricingCard_solo_thirdRow__TcJm9`,
	"normalPriceText": `PricingCard_solo_normalPriceText__4wB1V`
};
export default ___CSS_LOADER_EXPORT___;
