import React from 'react';
import style from './RecaptchaDescription.module.scss';

const recaptchaLink = (content: string, href: string) => (
  <a
    target='_blank'
    rel='noopener noreferrer'
    href={href}
    className={style.recaptchaLink}
  >
    {' '}{content}
  </a>);

export const RecaptchaDescription: React.FC = () => (
  <div className={style.recaptchaDescription} data-testid='recaptcha-description'>
    <span>
      {'Invisible reCAPTCHA by Google'}
      {recaptchaLink('Privacy Policy', 'https://policies.google.com/privacy')}
      {' '}and
      {recaptchaLink('Terms of Use', 'https://policies.google.com/terms')}
    </span>
  </div>
);
