import React, {
  createContext,
  ReactChild, useContext,
  useState
} from 'react';
import { initializeSegment } from '../../helpers/analytics/analytics';

export const SegmentContext = createContext(false);
export const useSegmentContext = (): boolean => useContext(SegmentContext);

const SegmentProvider: React.FC<{ children: ReactChild }> = ({ children }) => {
  const [loaded, setLoaded] = useState(false);

  if (!loaded) {
    initializeSegment(() => setLoaded(true));
  }

  return (
    <SegmentContext.Provider value={loaded}>
      { children }
    </SegmentContext.Provider>
  );
};

export default SegmentProvider;
