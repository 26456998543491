import React from 'react';
import { Box, Separator } from '@myob/myob-widgets';
import { TermsConditions } from 'components/terms/Terms';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { ProductData } from 'hooks/useGetProduct/types';
import { useProductData } from 'hooks/useProductData/useProductData';
import { getDiscountPrice } from 'utils/pricingUtils';
import isNzPPLProduct from 'utils/IsNzPPLProduct';
import { PricingCard } from '../pricingCard/PricingCard';
import style from './ProductFooter.module.scss';
import { FeatureList } from '../featureList/FeatureList';
import { getStyleProps } from './ProductFooter.style';

interface ProductHeadingProps {
  isFooterOpen: boolean;
  theme: string;
}

const getPricing = (productData: ProductData, isAnnualPrice: boolean) =>
  ({
    price: getDiscountPrice(productData?.items[0], isAnnualPrice, productData?.region),
    currency: productData.currency || 'AUD',
    isExcludeTax: isNzPPLProduct(productData?.items[0]?.productId),
    // when annual is moved to nbs this will be dynamic
    paymentSchedule: isAnnualPrice ? 'year' : 'month'
  });
export const ProductFooter: React.FC<ProductHeadingProps> =
  ({
    isFooterOpen,
    theme
  }) => {
    const {
      productData,
      baseFeatureList,
      baseAndModuleFeatureList,
      productId,
      showModulePrice,
      promoCode,
      availableTo
    } = useProductData();
    const styleProps = getStyleProps(theme);
    const { isAnnualPrice } = useSessionContext();
    return (
      <Box
        className={[style.productFooter, {
          [style.productFooterOpen]: isFooterOpen
        }]}
        {...styleProps.footer}
      >
        <Separator
          {...styleProps.separator}
        />
        <PricingCard
          pricing={getPricing(productData, isAnnualPrice)}
          theme={theme}
        />
        <FeatureList
          items={showModulePrice(productId) ? baseAndModuleFeatureList : baseFeatureList}
          theme={theme}
        />
        { promoCode && availableTo && <TermsConditions className={style.termsAndConditions}/>}
      </Box>
    );
  };
