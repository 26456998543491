import React from 'react';
import { TickList } from 'components/tickList/TickList';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import { productTrailFeaturesAU, productTrailFeaturesNZ } from './ProductFeatureMapping';
import { REGIONS } from '../../constants';
import laptopImage from '../../assets/images/laptop.png';
import './FeatureList.scss';

const FeatureList: React.FC = () => {
  const { region } = useSessionContext();
  const { isLoading, productData } = useGetProduct();

  if (isLoading) {
    return (<div data-testid='feature-list-loading'/>);
  }

  const productFeatures = (): string[] => {
    if (region === REGIONS.AUSTRALIA) {
      const trialDuration = productData?.items[0]?.trialDeal?.duration ?? 1;
      return productTrailFeaturesAU(trialDuration);
    }
    return productTrailFeaturesNZ;
  };

  return (
    <>
      <div className='feature-box-image-container'>
        <img
          className='feature-box-image'
          src={laptopImage}
          alt='laptop'
        />
      </div>
      <div className='feature-box-content-container'>
        <TickList
          data-testid='feature-list'
          styles={{ color: 'white', display: 'inline-block', marginTop: '2rem' }}
          items={productFeatures()}
        />
      </div>
    </>
  );
};

export default FeatureList;
