import React from 'react';
import { Stepper } from '@myob/myob-widgets';
import '../Stepper.scss';

const NewTrialStepper: React.FC<{activeStepNumber: '1'|'2'}> = ({ activeStepNumber }) =>
  <div className='trial-stepper'>
    <Stepper className={activeStepNumber === '2' ? 'final-step' : 'stepper'}
      activeStepNumber={activeStepNumber}
      steps={[
        { number: '1', title: 'Account setup', type: activeStepNumber === '1' ? 'incomplete' : 'complete' },
        { number: '2', title: 'Confirmation', type: activeStepNumber === '2' ? 'complete' : '' }
      ]}
    />
  </div>;

export default NewTrialStepper;
