import React from 'react';
import { Box, Text } from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import soloStyle from './PricingMolecule.solo.module.scss';
import { PricingMoleculeProps } from './types';

export const PricingMoleculeSolo: React.FC<PricingMoleculeProps> =
  ({
    price,
    denominator,
    currency
  }) => {
    const [dollars, cents] = price.split('.');
    const { promoCode, promotionEndDate } = useSessionContext();

    return (
      <Box
        className={soloStyle.pricingMolecule}
        data-testid='pricing-molecule'
        display='flex'
      >
        <Text
          className={soloStyle.leftInteger}
          as='span'
        >
          ${dollars}
        </Text>
        <Box
          display='flex'
          flexDirection='column'
        >
          <Text
            className={soloStyle.centsAmount}
            as='span'
          >
            .{cents} {currency} {promoCode && promotionEndDate ? '^' : ''}
          </Text>
          <Text
            className={soloStyle.denominator}
            as='span'
          >
            {denominator}
          </Text>
        </Box>
      </Box>
    );
  };
