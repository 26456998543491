// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar_solo_progressBarContainer__qXaT3 {
  width: 38rem;
  margin-bottom: 3.2rem;
}
@media screen and (max-width: 578px) {
  .ProgressBar_solo_progressBarContainer__qXaT3 {
    width: 100%;
    margin-bottom: 2.4rem;
  }
}

.ProgressBar_solo_progressBar__MBNDl {
  margin-bottom: 4rem;
}
@media screen and (max-width: 578px) {
  .ProgressBar_solo_progressBar__MBNDl {
    margin-bottom: 2.4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/generic/progressBar/ProgressBar.solo.module.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,qBAAA;AAAF;AAEE;EAJF;IAKI,WAAA;IACA,qBAAA;EACF;AACF;;AAEA;EACE,mBAAA;AACF;AACE;EAHF;IAII,qBAAA;EAEF;AACF","sourcesContent":["@import 'assets/scss/global';\n.progressBarContainer {\n  width: 38rem;\n  margin-bottom: 3.2rem;\n\n  @media screen and (max-width: $mobilePortraitWidth) {\n    width: 100%;\n    margin-bottom: 2.4rem;\n  }\n}\n\n.progressBar {\n  margin-bottom: 4rem;\n\n  @media screen and (max-width: $mobilePortraitWidth) {\n    margin-bottom: 2.4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBarContainer": `ProgressBar_solo_progressBarContainer__qXaT3`,
	"progressBar": `ProgressBar_solo_progressBar__MBNDl`
};
export default ___CSS_LOADER_EXPORT___;
