import { Box, Separator, Text } from '@myob/myob-widgets';
import React from 'react';
import { get } from 'lodash';
import { OfferPill } from './OfferPill';
import { PricingMolecule } from './PricingMolecule';
import { ProductData } from '../../hooks/useGetProduct/types';
import { ProductData as ModuleProductData } from '../../hooks/useGetModuleProduct/types';
import isNzPPLProduct from '../../utils/IsNzPPLProduct';
import {
  getDiscountPrice,
  getItemPrice,
  getProductTotalPrice,
  formatToTwoDecimalPlaces, getListPrice
} from '../../utils/pricingUtils';
import { getTooltip } from '../../utils/toolTipUtils';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';

export const PricingBox = (
  { productData, moduleProductData } :
  { productData: ProductData; moduleProductData?: ModuleProductData }
): React.JSX.Element => {
  const { isAnnualPrice } = useSessionContext();
  const productItems = get(productData, 'items');
  const region = get(productData, 'region');
  const { productId } = productItems[0];

  const offerText = productItems[0].deal?.promotionName;
  const getModuleProductPriceText = (gstDescription: string) => {
    if (!moduleProductData?.items) {
      return '';
    }

    const moduleProductPrice = formatToTwoDecimalPlaces(
      getItemPrice(moduleProductData.items[0], isNzPPLProduct(productId))
    );

    return `+ $${moduleProductPrice}/month ${gstDescription} per employee paid`;
  };

  const gstDescription = isNzPPLProduct(productId) ? '+ GST' : 'inc. GST';
  const gstDescriptionForToolTip = isNzPPLProduct(productId) ? '(+ GST)' : '(inc. GST)';
  const modulePriceText = getModuleProductPriceText(gstDescription);
  const priceDenominatorMonthly = isNzPPLProduct(productId) ? '/month (+ GST)' : '/month (inc. GST)';

  const roundedNormalPrice = Math.round(+getListPrice(productItems[0], isAnnualPrice, region));
  const discountedPrice = getDiscountPrice(productItems[0], isAnnualPrice, region);
  const roundedDiscountedPrice = Math.round(Number(discountedPrice));
  const minTotalPrice = getProductTotalPrice(productItems, isAnnualPrice);

  return (
    <Box
      className='pricing-box'
      data-testid='pricing-box'
      backgroundColor='#f9f9f9'
      borderRadius='xl'
      padding='lg'
      paddingBottum='22px'
    >
      {offerText && (
        <div data-testid='promo-code'>
          <OfferPill
            className='promo-code'
            offerText={
              <Text
                as='span'
                fontSize='inherit'
                fontWeight='medium'
              >
                {offerText}
              </Text>
            }
          />
        </div>
        )}
      <PricingMolecule
        price={discountedPrice}
        denominator={isAnnualPrice ? '/year (inc. GST)' : priceDenominatorMonthly}
        currency={productItems[0].currency}
      />
      {roundedDiscountedPrice < roundedNormalPrice && (
      <Box
        className='normal-price-text-box'
        data-testid='normal-price-text-box'
      >
        <Text
          className='normal-price-text'
        >
          Normally ${roundedNormalPrice.toString()}{isAnnualPrice ? '/year' : '/month'}
        </Text>
      </Box>
      )}
      {modulePriceText && (
        <>
          <div data-testid='min-monthly-total'>
            <Separator
              data-testid='total-price-separator'
              marginTop='sm'
              marginBottom='sm'
            />
            <Box
              className='min-monthly-total'
              display='flex'
              justifyContent='space-between'
              flexDirection='row'
            >
              <Box
                display='flex'
                className='monthly-total'
                alignItems='end'
              >
                <Text fontWeight='bold'
                  fontSize='sm'
                  marginBottom='clear'
                >
                  Min. {isAnnualPrice ? 'Yearly' : 'Monthly'} total
                </Text>
                <Text
                  fontSize='xs'
                  marginBottom='clear'
                >&nbsp;(inc. GST)</Text>
              </Box>
              <Text marginBottom='clear'
                tone='neutralStrongest'
              > ${minTotalPrice} {productItems[0].currency}</Text>
            </Box>
          </div>
          <div data-testid='module-price-text'>
            <Separator
              marginTop='sm'
              marginBottom='sm'
            />
            <div style={{ display: 'flex' }}>
              <Text
                className='module-product-price'
                tone='neutralStrongest'
                marginBottom='clear'
                fontSize='xs'
              >{modulePriceText}&nbsp;</Text>
              {getTooltip(productItems[0], getModuleProductPriceText(gstDescriptionForToolTip))}
            </div>
          </div>
        </>
        )}
    </Box>
  );
};
