import axios from 'helpers/axios/axios';
import config from 'Config';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';

type UseExistingUserCheckType = {
  isLoading: boolean;
  isNewUser: boolean;
  isError: boolean;
  isEmailChecked: boolean;
  identityId: string;
}

export const useExistingUserCheck = (emailValue: string): UseExistingUserCheckType => {
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const { setIsNewUser } = useSessionContext();
  const {
    isLoading, data, isError
  } = useQuery(
    ['existingUser', emailValue],
    () => axios.get(`${config.API_EXISTING_USER_CHECK}`, { params: { email: emailValue } }),
    { enabled: !!emailValue, retry: false }
  );

  const isNewUser = !data?.data.guid;
  useEffect(() => {
    (data) && setIsEmailChecked(true);
    setIsNewUser(isNewUser);
  }, [data]);

  return {
    isLoading, isNewUser, isError, isEmailChecked, identityId: String(data?.data?.guid?.toString().toLowerCase() ?? '')
  };
};
