import { ELEMENTS } from './Elements';
import { FEELIX_THEMES, SUBSCRIPTION_TYPE } from '../../constants';

const DEFAULT_THEME = 'default';
class RecordKey {
  flow: string;

  theme: string;

  constructor() {
    this.flow = '';
    this.theme = DEFAULT_THEME;
  }

  static from(flow: string, theme?: string) {
    const key = new RecordKey();
    key.theme = theme || key.theme;
    key.flow = flow || key.flow;
    return key;
  }

  static fromDefaultTheme(flow: string) {
    return RecordKey.from(flow, DEFAULT_THEME);
  }

  static fromDefaultFlow(theme: string) {
    return RecordKey.from('', theme);
  }

  toKey(): string {
    return `${this.theme}-${this.flow}`;
  }
}

class RecordBuilder {
  registryRecord: RegistryRecord;

  key: RecordKey;

  constructor(registryRecord: RegistryRecord) {
    this.registryRecord = registryRecord;
    this.key = new RecordKey();
  }

  withFlow(flow: string) {
    this.key.flow = flow;
    return this;
  }

  withTheme(theme: string) {
    this.key.theme = theme;
    return this;
  }

  set(value: string) {
    this.registryRecord.record[this.key.toKey()] = value;
    this.key = new RecordKey();
    return this;
  }
}
class RegistryRecord {
  record: Record<string, string>;

  constructor() {
    this.record = {} as Record<string, string>;
  }

  configure(configure: (configurer: RecordBuilder) => void) {
    return configure(new RecordBuilder(this));
  }
}

class Registry {
  register: Record<symbol, RegistryRecord>;

  constructor() {
    this.register = {} as Record<symbol, RegistryRecord>;
  }

  forLabel(label: symbol) {
    this.register[label] = this.register[label] || new RegistryRecord();
    return this.register[label];
  }

  forElement(element: symbol) {
    if (!this.register[element]) throw new Error('Element not found');
    return this.register[element];
  }

  findLabel(element: symbol, key: RecordKey) {
    return this.forElement(element).record[key.toKey()];
  }
}

const registry = new Registry();

// configuration start

registry.forLabel(ELEMENTS.EMAIL_SUBMIT_BUTTON)
  .configure((configurer) => {
    configurer.withFlow(SUBSCRIPTION_TYPE.TRIAL).set('Start your Trial');
    configurer.withFlow(SUBSCRIPTION_TYPE.SUBSCRIBE).set('Continue');
    configurer.withTheme(FEELIX_THEMES.SOLO).set('Create account');
  });

registry.forLabel(ELEMENTS.EMAIL_PAGE_HEADING_ONE)
  .configure((configurer) => {
    configurer.withTheme(FEELIX_THEMES.SOLO).set('Gone solo? Get Solo.');
  });

registry.forLabel(ELEMENTS.EMAIL_PAGE_HEADING_TWO)
  .configure((configurer) => {
    configurer.withTheme(FEELIX_THEMES.SOLO).set('Create your account in 3 easy steps.');
  });

registry.forLabel(ELEMENTS.FLOW_STEP_ONE)
  .configure((configurer) => {
    configurer.withFlow(SUBSCRIPTION_TYPE.TRIAL).set('Account Setup');
    configurer.withFlow(SUBSCRIPTION_TYPE.SUBSCRIBE).set('Account Setup');
  });

registry.forLabel(ELEMENTS.FLOW_STEP_THREE)
  .configure((configurer) => {
    configurer.withTheme(FEELIX_THEMES.SOLO).withFlow(SUBSCRIPTION_TYPE.TRIAL).set('Payment details');
    configurer.withTheme(FEELIX_THEMES.SOLO).withFlow(SUBSCRIPTION_TYPE.SUBSCRIBE).set('Payment details');
  });

registry.forLabel(ELEMENTS.PAYMENT_FORM_SUBMIT_BUTTON)
  .configure((configurer) => {
    configurer.withTheme(FEELIX_THEMES.SOLO).set('Confirm');
    configurer.withTheme(FEELIX_THEMES.MODERN).set('Next');
  });

registry.forLabel(ELEMENTS.ACCOUNT_DETAILS_INPUT_GIVEN_NAME)
  .configure((configurer) => {
    configurer.withTheme(FEELIX_THEMES.SOLO).set('Given name');
  });

registry.forLabel(ELEMENTS.ACCOUNT_DETAILS_INPUT_FAMILY_NAME)
  .configure((configurer) => {
    configurer.withTheme(FEELIX_THEMES.SOLO).set('Family name');
  });

registry.forLabel(ELEMENTS.ACCOUNT_DETAILS_INPUT_PHONE)
  .configure((configurer) => {
    configurer.withTheme(FEELIX_THEMES.SOLO).set('Phone');
  });

registry.forLabel(ELEMENTS.FREE_TRIAL_MESSAGE)
  .configure((configurer) => {
    configurer.withTheme(FEELIX_THEMES.SOLO).set('Try free for %s days');
  });

// configuration end

export const getLabel = (
  element: symbol,
  flow: string,
  theme?: string
) =>
  registry.findLabel(element, RecordKey.from(flow, theme)) ||
  registry.findLabel(element, RecordKey.fromDefaultFlow(theme || DEFAULT_THEME)) ||
  registry.findLabel(element, RecordKey.fromDefaultTheme(flow));
