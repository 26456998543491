import { useQuery } from 'react-query';
import axios from 'helpers/axios/axios';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import {
  ContentQuery,
  ContentResponse
} from 'providers/contentProvider/types';
import config from '../../Config';

export const useContent = (queries: ContentQuery): ContentResponse => {
  const { isLoading } = useAuth0();

  const { data, ...rest } = useQuery(
    ['content', JSON.stringify(queries)],
    () => axios.post(config.API_CONTENT_ENDPOINT, queries),
    {
      enabled: Boolean(queries.partials.length) && !isLoading,
      retry: false
    }
  );

  return {
    data: data?.data ?? {},
    ...rest
  };
};
