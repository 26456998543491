import axios from 'helpers/axios/axios';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import config from 'Config';
import { useQuery } from 'react-query';
import qs from 'qs';
import { useGetAccessToken } from 'hooks/useGetAcessToken/useGetAccessToken';
import { useAccount } from 'hooks/useAccountsCreation/useAccount';
import { REQUEST_HEADERS } from '../../constants';
import { AccountDetails, AccountResult } from './types';

export const useAccountCreation = (contactId:string | undefined): AccountResult => {
  const { isLoading: isAuth0Loading, appState } = useAuth0();
  const account = useAccount();
  const { accessToken } = useGetAccessToken();
  const accountsRequest = {
    ...account,
    primary_contact: {
      id: contactId
    }
  };

  const options = {
    headers: {
      ...REQUEST_HEADERS,
      Authorization: `Bearer ${accessToken}`
    }
  };

  const {
    isLoading: isAccountsLoading, data, isError
  } =
    useQuery(
      'accounts',
      () =>
        axios.post<AccountDetails>(
          config.API_ACCOUNTS_ENDPOINT,
          qs.stringify(accountsRequest),
          options
        ),
      { enabled: !!appState && !!contactId && !!accessToken, retry: false }
    );

  const isLoading = isAuth0Loading || isAccountsLoading;
  const accountDetails = data?.data;
  return { isLoading, accountDetails, isError };
};
