// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccountDetailsForm_solo_container__lqXv- {
  width: 448px;
}
@media screen and (max-width: 578px) {
  .AccountDetailsForm_solo_container__lqXv- {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/generic/accountDetailsForm/AccountDetailsForm.solo.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAEE;EAFF;IAGI,WAAA;EACF;AACF","sourcesContent":["@import 'assets/scss/global';\n\n.container {\n  width: 448px;\n  @media screen and (max-width: $mobilePortraitWidth) {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AccountDetailsForm_solo_container__lqXv-`
};
export default ___CSS_LOADER_EXPORT___;
