// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailForm_solo_container__e3n\\+S {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 578px) {
  .EmailForm_solo_container__e3n\\+S {
    width: 100%;
  }
}

@media screen and (max-width: 578px) {
  .EmailForm_solo_inputContainer__jeJaA {
    width: 100%;
  }
}

.EmailForm_solo_input__OLzXu {
  margin-bottom: 1.2rem;
  width: 38rem;
}
@media screen and (max-width: 578px) {
  .EmailForm_solo_input__OLzXu {
    width: 100%;
  }
}

.EmailForm_solo_backgroundImage__AcWA5 {
  display: none;
  width: 100vw;
  height: auto;
  object-fit: contain;
  margin: 0 -2rem 2.4rem -2rem;
}
@media screen and (max-width: 578px) {
  .EmailForm_solo_backgroundImage__AcWA5 {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/generic/emailForm/EmailForm.solo.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AADF;AAEE;EAJF;IAKI,WAAA;EACF;AACF;;AAGE;EADF;IAEI,WAAA;EACF;AACF;;AAEA;EACE,qBAAA;EACA,YAAA;AACF;AAAE;EAHF;IAII,WAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,4BAAA;AAGF;AAFE;EANF;IAOI,aAAA;EAKF;AACF","sourcesContent":["@import 'assets/scss/global';\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  @media screen and (max-width: $mobilePortraitWidth) {\n    width: 100%;\n  }\n}\n\n.inputContainer {\n  @media screen and (max-width: $mobilePortraitWidth) {\n    width: 100%;\n  }\n}\n\n.input {\n  margin-bottom: 1.2rem;\n  width: 38rem;\n  @media screen and (max-width: $mobilePortraitWidth) {\n    width: 100%;\n  }\n}\n\n.backgroundImage {\n  display: none;\n  width: 100vw;\n  height: auto;\n  object-fit: contain;\n  margin: 0 -2rem 2.4rem -2rem;\n  @media screen and (max-width: $mobilePortraitWidth) {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EmailForm_solo_container__e3n+S`,
	"inputContainer": `EmailForm_solo_inputContainer__jeJaA`,
	"input": `EmailForm_solo_input__OLzXu`,
	"backgroundImage": `EmailForm_solo_backgroundImage__AcWA5`
};
export default ___CSS_LOADER_EXPORT___;
