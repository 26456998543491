import axios from 'helpers/axios/axios';
import { AxiosResponse } from 'axios';
import config from '../../Config';

export const bsbValidatorAU = async (bankCode: string): Promise<boolean> => {
  const response: AxiosResponse = await axios.get(config.API_VALIDATE_BANK_CODE, {
    params: { bankCode }
  });

  return response.data?.validBsb ?? false;
};
