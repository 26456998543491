import React from 'react';
import { Heading, Text } from '@myob/myob-widgets';

export const ProductTitle = ({ header, content }: { header: string, content: string }): React.JSX.Element => (
  <div
    className='product-title'
    data-testid='product-title'
  >
    <Text
      data-testid='product-title-header'
      as='span'
      display='block'
      tone='neutralStrongest'
      fontSize='sm'
    >
      {header}
    </Text>
    <Heading
      className='product-title-content'
      data-testid='product-title-content'
    >
      {content}
    </Heading>
  </div>);
