import React from 'react';
import { Box, ProgressBar as FeelixProgressBar, Heading } from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import { FLOW_STEP_MAPPING } from '../../../constants';
import style from './ProgressBar.solo.module.scss';

const getElement = (flowStep: number) => {
  switch (flowStep) {
    case 1: return ELEMENTS.FLOW_STEP_ONE;
    case 2: return ELEMENTS.FLOW_STEP_TWO;
    case 3: return ELEMENTS.FLOW_STEP_THREE;
    default: throw new Error('Invalid flow step');
  }
};
export const ProgressBar: React.FC = () => {
  const { flowStep, subscriptionType } = useSessionContext();
  const { getLabels } = useContentContext();
  if (flowStep === -1) return null;
  const total = FLOW_STEP_MAPPING[subscriptionType].length;
  return (
    <Box
      className={style.progressBarContainer}
    >
      <FeelixProgressBar
        tone='brand'
        size='sm'
        className={style.progressBar}
        isAnimating={{
          from: flowStep + 1, // flow step is 0 based
          to: total
        }}
      />
      <Heading
        level={6}
        marginBottom='4xs'
      >
        Step {flowStep + 1} of {total}
      </Heading>
      <Heading level={2}>
        {getLabels(getElement(flowStep + 1))}
      </Heading>
    </Box>
  );
};
