import React from 'react';
import { Input } from '@myob/myob-widgets';
import { DeepMap, FieldError } from 'react-hook-form';
import { REGULAR_EXPRESSIONS } from '../../../constants';
import { DirectDebitDetail } from '../types';
import './directDebitForm.scss';
import { trimFocusEventInputValue } from '../../../helpers/trim/trim';

export const DirectDebitContainerNZ: React.FC<{
  register:any,
  errors:DeepMap<DirectDebitDetail, FieldError>,
}> = ({
  register,
  errors
}) => (
  <>
    <Input
      className={'input__field'}
      id='bank'
      name='bank'
      label='Bank*'
      reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.BANK })}
      errorMessage={
        errors.bank &&
                (errors.bank.type === 'required' ?
                  'Bank ID is required' : 'Please enter a valid bank ID')}
    />
    <Input
      className={'input__field'}
      id='branch'
      name='branch'
      label='Branch*'
      reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.BRANCH })}
      errorMessage={
        errors.branch &&
                (errors.branch.type === 'required' ?
                  'Branch number is required' : 'Please enter a valid branch number')}
    />
    <Input
      className={'input__field'}
      id='account'
      name='account'
      label='Account*'
      reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.ACCOUNT })}
      errorMessage={
        errors.account &&
                (errors.account.type === 'required' ?
                  'Account number is required' : 'Please enter a valid account number')}
    />
    <Input
      className={'input__field'}
      id='suffix'
      name='suffix'
      label='Suffix*'
      reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.SUFFIX })}
      errorMessage={
        errors.suffix &&
                (errors.suffix.type === 'required' ?
                  'Suffix is required' : 'Please enter a valid suffix')}
    />
    <Input
      className={'input__field'}
      id='accountName'
      name='accountName'
      label='Account Name*'
      onBlur={trimFocusEventInputValue}
      reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.ACCOUNT_NAME })}
      errorMessage={
        errors.accountName &&
                (errors.accountName.type === 'required' ?
                  'Account name is required' : 'Please enter a valid account name')}
    />
    <br />
  </>
);
