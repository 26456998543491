import React from 'react';
import { OfferPill } from 'components/productCard/OfferPill';
import { Text } from '@myob/myob-widgets';
import style from './OfferText.module.scss';
import { FEELIX_THEMES } from '../../../../constants';

interface OfferTextProps {
  text: string;
  theme: string;
}

export const OfferText: React.FC<OfferTextProps> =
  ({ text, theme }) => {
    if (!text || theme === FEELIX_THEMES.SOLO) return null;

    return (
      <OfferPill
        className={style.text}
        offerText={
          <Text
            as='span'
            fontSize='inherit'
            fontWeight='medium'
          >
            {text}
          </Text>
      }
      />
    );
  };
