import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Box,
  Button,
  ButtonLink,
  ErrorIcon,
  Input,
  Pill,
  Spinner,
  TagIcon,
  Text,
  TickIcon
} from '@myob/myob-widgets';
import { useProductData } from 'hooks/useProductData/useProductData';
import { trackClickEvent } from 'hooks/GTM4Analytics/useGTMAnalytics';
import usePromoCodeBoxState from 'hooks/usePromoCodeBoxState/usePromoCodeBoxState';
import {
  GTM4_ANALYTICS_CONSTANTS, ANALYTICS_ID, PROMO_CODE_STATE, FEELIX_THEMES
} from '../../constants';
import defaultStyle from './PromoCodeBox.module.scss';
import soloStyle from './PromoCodeBox.solo.module.scss';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { trimFocusEventInputValue } from '../../helpers/trim/trim';

const TICK_ICON = {
  [PROMO_CODE_STATE.VALID]: <TickIcon className='promo-code-input-tick-icon'/>,
  [PROMO_CODE_STATE.INVALID]: <ErrorIcon className='promo-code-input-error-icon'/>
};

export const PromoCodeBox = (): React.ReactElement => {
  const { productData } = useProductData();
  const productItems = productData?.items;
  const promoCode = productItems[0].deal?.promoCode ?? '';
  const promotionName = productItems[0].deal?.promotionName ?? '';
  const [inputValue, setInputValue] = useState(promoCode);
  const { isLoading: isVerifying, state: promoCodeState, updatePromoCodeBoxState } = usePromoCodeBoxState();
  const { theme } = useSessionContext();
  const handleUpdatePromoCode = () => {
    if (inputValue.trim() !== '') {
      trackClickEvent(GTM4_ANALYTICS_CONSTANTS.PROMO_CODE_APPLY_BUTTON_CLICK, { promo_code: inputValue });
      updatePromoCodeBoxState(inputValue);
    }
  };
  const currentStyle = theme === FEELIX_THEMES.SOLO ? soloStyle : defaultStyle;

  const [showPromoBox, setShowPromoBox] = useState(false);

  if (showPromoBox) {
    return (
      <Box
        className={`${currentStyle.promoCodeGroup} ${isMobile ? currentStyle.isMobile : ''}`}
        data-testid='promo-code-group'
      >
        <Text
          className={`${currentStyle.promoCodeInputLabel}`}
          as='span' color='#74767B' fontSize='sm'
        >Apply promotional code</Text>
        <Box
          className={`${currentStyle.promoCodeInputText} ${currentStyle.promoCodeInputText}`}
        >
          <Box>
            <Input
              className={`${currentStyle.promoCodeInputBox} ${currentStyle[promoCodeState.toLowerCase()]}`}
              data-testid='promo-code-input'
              disabled={isVerifying}
              containerClassName={`${currentStyle.promoCodeInputContainer}`}
              hideLabel={true}
              name='promoCode'
              value={inputValue}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setInputValue(e.target.value)}
              onBlur={trimFocusEventInputValue}
              suffixIcon={TICK_ICON[promoCodeState]}
              {...(promoCodeState !== PROMO_CODE_STATE.EMPTY && {
                errorVariant: promoCodeState === PROMO_CODE_STATE.VALID ? 'success' : 'danger',
                errorMessage: promoCodeState === PROMO_CODE_STATE.VALID ?
                    'Promotional code applied' : 'Invalid promotional code'
              })}
            />
            <Text
              className={`${currentStyle.promoCodeVerificationText} ${currentStyle[promoCodeState.toLowerCase()]}`}
              fontSize='sm'
            />
          </Box>
          {isVerifying ? (
            <Button
              className={`${currentStyle.promoCodeApplyButton}`}
              data-testid='promo-code-verifying-button' disabled={true}
            >
              <Spinner isLoading={true} size={'small'} tone={'neutral'}/>
            </Button>
            ) : (
              <Button
                className={`${currentStyle.promoCodeApplyButton}`}
                data-testid='promo-code-apply-button'
                onClick={handleUpdatePromoCode}
              >
                <p
                  className={`${currentStyle.promoCodeApplyButtonText}`}
                >Apply</p>
              </Button>
            )}
        </Box>
        {promoCodeState !== PROMO_CODE_STATE.EMPTY && (
          <div>
            {promotionName &&
            <Pill label={promotionName} prefixAccessory={<TagIcon color={'brand'}/>}
              className={`${currentStyle.promoCodeTag}`}
            />
            }
          </div>
          )}
      </Box>
    );
  }
  return (
    <div>
      <ButtonLink className={'promo-code-link'} onClick={() => setShowPromoBox(true)}>
        <a id={ANALYTICS_ID.LINK_HAVE_PROMO_CODE}>Have a promotional code?</a>
      </ButtonLink>
    </div>
  );
};
