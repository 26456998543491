import * as Sentry from '@sentry/react';
import config from './Config';
import packageJson from '../package.json';

if (config.SENTRY_ENABLED) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE
  });
  Sentry.setTags({
    source: 'frontend',
    version: packageJson.version
  });
}
