import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import { get } from 'lodash';
import { pushToDataLayer } from '../Analytics/useAnalyticsHelper';
import { GTM4_ANALYTICS_CONSTANTS as GTM_ANALYTICS_CONSTANTS, SUBSCRIPTION_TYPE } from '../../constants';
import {
  EcommerceEvent,
  EcommerceItems,
  EcommercePrice,
  MarketingConsent,
  PurchaseEvent,
  UserStatus,
  UserStatusEcommerceEvent
} from './types';
import { useAuth0 } from '../Auth0/Auth0';

export const pushAsEcommerceToDataLayer = (eventData: EcommerceEvent): boolean => {
  pushToDataLayer({ ecommerce: null });
  const eventCopy = JSON.parse(JSON.stringify(eventData));
  const { event } = eventCopy;
  delete eventCopy.event;
  pushToDataLayer({
    event,
    ecommerce: {
      ...eventCopy
    }
  });
  return true;
};

export const useGetEcommerceItems = (): EcommerceItems[] => {
  const { subscriptionType, promoCode } = useSessionContext();
  const { productData } = useGetProduct();
  const { totalDiscountAmt } = useGetEcommercePrice();

  const isTrial = subscriptionType === SUBSCRIPTION_TYPE.TRIAL;

  return productData?.items?.map((product) => ({
    item_id: product.productId,
    item_name: `${product.productFamily} ${product.productTier}`.toLowerCase(),
    coupon: promoCode,
    discount: totalDiscountAmt,
    item_brand: 'myob',
    item_category: product.productFamily?.toLowerCase(),
    item_free_period: product.deal?.freePeriod,
    item_payment_state: isTrial ? 'without payment' : 'with payment',
    price: isTrial ? 0 : Number(product.priceWithoutTax),
    quantity: 1
  })) || [];
};

export const useGetEcommercePrice = (): EcommercePrice => {
  const { productData } = useGetProduct();
  const { subscriptionType } = useSessionContext();

  if (subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE) {
    return {
      totalPriceWithoutTax: productData.totalPriceWithoutTax,
      tax: productData.tax,
      totalDiscountAmt: productData.totalDiscountAmt,
      totalPrice: productData.totalPrice,
      currency: productData.currency
    };
  }

  return {
    totalPriceWithoutTax: 0,
    tax: 0,
    totalDiscountAmt: 0,
    totalPrice: 0,
    currency: productData.currency
  };
};

const useBaseEcommerceEvent = () => {
  const ecommerceItems = useGetEcommerceItems();
  const { promoCode } = useSessionContext();
  const { currency, totalPrice } = useGetEcommercePrice();
  return {
    currency,
    value: totalPrice,
    coupon: promoCode,
    items: ecommerceItems
  };
};

export const useGetBeginCheckoutEvent = (): EcommerceEvent => (
  {
    event: GTM_ANALYTICS_CONSTANTS.BEGIN_CHECKOUT,
    ...useBaseEcommerceEvent()
  }
);

export const useGetAddShippingInfoEvent = (isNewUser: boolean): UserStatusEcommerceEvent => (
  {
    user_status: isNewUser ? UserStatus.NEW_USER : UserStatus.EXISTING_USER,
    event: GTM_ANALYTICS_CONSTANTS.ADD_SHIPPING_INFO,
    ...useBaseEcommerceEvent()
  }
);

export const useGetCheckoutSignInEvent = (isNewUser: boolean): UserStatusEcommerceEvent => ({
  ...useGetAddShippingInfoEvent(isNewUser),
  event: GTM_ANALYTICS_CONSTANTS.CHECKOUT_SIGN_IN
});

export const useGetAddPaymentInfoEvent = (): UserStatusEcommerceEvent => {
  const { appState } = useAuth0();
  const sendPromotions = get(appState, 'clientInformation.sendPromotions');
  const isNewUser = get(appState, 'clientInformation.isNewUser');
  const base = useGetAddShippingInfoEvent(!!isNewUser);
  const { items } = base;
  return {
    ...base,
    event: GTM_ANALYTICS_CONSTANTS.ADD_PAYMENT_INFO,
    items: [...items.map((it) => ({
      ...it,
      item_marketing_consent: sendPromotions ? MarketingConsent.SELECTED : MarketingConsent.NOT_SELECTED
    }))]
  };
};

export const useGetPurchaseEvent = (): PurchaseEvent => {
  const { orderNumber, paymentMethod } = useSessionContext();
  const { appState } = useAuth0();
  const sendPromotions = get(appState, 'clientInformation.sendPromotions');
  const isNewUser = get(appState, 'clientInformation.isNewUser');

  const { tax } = useGetEcommercePrice();
  const base = useBaseEcommerceEvent();
  const { items } = base;
  return {
    ...useBaseEcommerceEvent(),
    items: [...items.map((it) => ({
      ...it,
      item_marketing_consent: sendPromotions ? MarketingConsent.SELECTED : MarketingConsent.NOT_SELECTED
    }))],
    event: 'purchase_ga4',
    transaction_id: orderNumber,
    tax,
    shipping: 0,
    payment_type: paymentMethod,
    user_status: isNewUser ? UserStatus.NEW_USER : UserStatus.EXISTING_USER
  };
};
