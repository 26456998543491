// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PricingCard_pricingCard__1QghE {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background: rgb(249, 249, 249);
  padding: 2.8rem;
  border-radius: 2rem;
  width: 100%;
  margin: 0 0 3.2rem 0;
}
.PricingCard_pricingCard__1QghE .PricingCard_price__IHov8 {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
}
.PricingCard_pricingCard__1QghE .PricingCard_priceFirstColumn__YdSkb {
  font-size: 3rem;
  font-weight: bold;
  color: #000000;
}
.PricingCard_pricingCard__1QghE .PricingCard_priceSecondColumn__PqFSs {
  font-size: 1.5rem;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.PricingCard_pricingCard__1QghE .PricingCard_priceSecondColumnFirstRow__\\+ZcZe {
  font-weight: bold;
  color: #000000;
  font-size: 0.75rem;
}
.PricingCard_pricingCard__1QghE .PricingCard_secondRow__GNmru {
  padding: 0.25rem;
}
.PricingCard_pricingCard__1QghE .PricingCard_thirdRow__8QwDO {
  padding: 0.25rem;
}
.PricingCard_pricingCard__1QghE .PricingCard_normalPriceText__uK-rT {
  margin-top: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/components/generic/productCard/pricingCard/PricingCard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,8BAAA;EACA,eAAA;EACA,mBAAA;EACA,WAAA;EACA,oBAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,aAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAGE;EACE,iBAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AADJ;AAIE;EACE,iBAAA;EACA,cAAA;EACA,kBAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;AAME;EACE,gBAAA;AAJJ;AAME;EACE,kBAAA;AAJJ","sourcesContent":[".pricingCard {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n  background: rgb(249, 249, 249);\n  padding: 2.8rem;\n  border-radius: 2rem;\n  width: 100%;\n  margin: 0 0 3.2rem 0;\n\n  .price {\n    font-size: 2rem;\n    font-weight: bold;\n    display: flex;\n  }\n\n  .priceFirstColumn {\n    font-size: 3rem;\n    font-weight: bold;\n    color: #000000;\n  }\n\n  .priceSecondColumn {\n    font-size: 1.5rem;\n    color: #000000;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n  }\n\n  .priceSecondColumnFirstRow {\n    font-weight: bold;\n    color: #000000;\n    font-size: .75rem;\n  }\n\n  .secondRow {\n    padding: .25rem;\n  }\n\n  .thirdRow {\n    padding: .25rem;\n  }\n  .normalPriceText {\n    margin-top: .8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pricingCard": `PricingCard_pricingCard__1QghE`,
	"price": `PricingCard_price__IHov8`,
	"priceFirstColumn": `PricingCard_priceFirstColumn__YdSkb`,
	"priceSecondColumn": `PricingCard_priceSecondColumn__PqFSs`,
	"priceSecondColumnFirstRow": `PricingCard_priceSecondColumnFirstRow__+ZcZe`,
	"secondRow": `PricingCard_secondRow__GNmru`,
	"thirdRow": `PricingCard_thirdRow__8QwDO`,
	"normalPriceText": `PricingCard_normalPriceText__uK-rT`
};
export default ___CSS_LOADER_EXPORT___;
