// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/solo-bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackgroundImage_solo_backgroundImage__ZG8sg {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: 100%;
  height: 60%;
  width: 100%;
  position: absolute;
  background-repeat: no-repeat;
}
@media screen and (max-width: 578px) {
  .BackgroundImage_solo_backgroundImage__ZG8sg {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/generic/background/BackgroundImage.solo.module.scss"],"names":[],"mappings":"AAEA;EACE,yDAAA;EACA,sBAAA;EACA,yBAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,4BAAA;AADF;AAEE;EARF;IASI,aAAA;EACF;AACF","sourcesContent":["@import 'assets/scss/global';\n\n.backgroundImage {\n  background-image: url('../../../assets/images/solo-bg.svg');\n  background-size: cover;\n  background-position: 100%;\n  height: 60%;\n  width: 100%;\n  position: absolute;\n  background-repeat: no-repeat;\n  @media screen and (max-width: $mobilePortraitWidth) {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundImage": `BackgroundImage_solo_backgroundImage__ZG8sg`
};
export default ___CSS_LOADER_EXPORT___;
