import React from 'react';
import { Box } from '@myob/myob-widgets';

export const OfferPill: (props: { offerText: React.JSX.Element, className: string }) => React.JSX.Element =
    (props: { offerText: React.JSX.Element, className: string }) => (
      <Box
        className={props.className}
        borderRadius='xl'
        backgroundColor='brandWeaker'
        display='inline-flex'
        alignItems='center'
        gap='3xs'
      >
        {props.offerText}
      </Box>
    );

