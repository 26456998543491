// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductHeading_chevron__ol4D6, .ProductHeading_chevronOpen__\\+sWjE {
  width: 32px;
  height: 32px;
  transition: transform 0.5s ease;
  display: none;
  position: absolute;
  right: 2.4rem;
  top: 1.6rem;
}
@media screen and (max-width: 578px) {
  .ProductHeading_chevron__ol4D6, .ProductHeading_chevronOpen__\\+sWjE {
    display: block;
  }
}
.ProductHeading_chevron__ol4D6.ProductHeading_chevronOpen__\\+sWjE, .ProductHeading_chevronOpen__\\+sWjE.ProductHeading_chevronOpen__\\+sWjE {
  transform: rotate(180deg);
}

.ProductHeading_rowOne__rDttH::after {
  content: attr(data-content-desktop);
  display: block;
}
@media screen and (max-width: 578px) {
  .ProductHeading_rowOne__rDttH::after {
    content: attr(data-content-mobile);
  }
}

.ProductHeading_rowTwo__NWXMP::after {
  content: attr(data-content-desktop);
  display: block;
}
@media screen and (max-width: 578px) {
  .ProductHeading_rowTwo__NWXMP::after {
    content: attr(data-content-mobile);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/generic/productCard/productHeader/ProductHeading.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,+BAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;AADF;AAEE;EARF;IASI,cAAA;EACF;AACF;AACE;EACE,yBAAA;AACJ;;AAGA;EACE,mCAAA;EACA,cAAA;AAAF;AAEE;EAJF;IAKI,kCAAA;EACF;AACF;;AAEA;EACE,mCAAA;EACA,cAAA;AACF;AACE;EAJF;IAKI,kCAAA;EAEF;AACF","sourcesContent":["@import '../../../../assets/scss/global';\n\n.chevron, .chevronOpen {\n  width: 32px;\n  height: 32px;\n  transition: transform 0.5s ease;\n  display: none;\n  position: absolute;\n  right: 2.4rem;\n  top: 1.6rem;\n  @media screen and (max-width: $mobilePortraitWidth) {\n    display: block;\n  }\n\n  &.chevronOpen {\n    transform: rotate(180deg);\n  }\n}\n\n.rowOne::after {\n  content: attr(data-content-desktop);\n  display: block;\n\n  @media screen and (max-width: $mobilePortraitWidth) {\n    content: attr(data-content-mobile);\n  }\n}\n\n.rowTwo::after {\n  content: attr(data-content-desktop);\n  display: block;\n\n  @media screen and (max-width: $mobilePortraitWidth) {\n    content: attr(data-content-mobile);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chevron": `ProductHeading_chevron__ol4D6`,
	"chevronOpen": `ProductHeading_chevronOpen__+sWjE`,
	"rowOne": `ProductHeading_rowOne__rDttH`,
	"rowTwo": `ProductHeading_rowTwo__NWXMP`
};
export default ___CSS_LOADER_EXPORT___;
