import {
  Box, TickIcon, Text, SuccessIcon
} from '@myob/myob-widgets';
import React from 'react';
import style from './FeatureList.solo.module.scss';
import { FEELIX_THEMES } from '../../../../constants';

interface FeatureListProps {
  items: string[];
  theme: string;
}
export const FeatureList: React.FC<FeatureListProps> =
  ({
    items,
    theme
  }): React.JSX.Element => (
    <ul
      className={style.featureListContainer}
      data-testid='feature-list-container'
    >
      {items.map((feature) => (
        <li key={feature} data-testid='feature-item'>
          <Box
            alignItems='flex-start'
            display='flex'
            gap='2xs'
            color='neutralStrongest'
            marginBottom={theme === FEELIX_THEMES.SOLO ? 'sm' : 'md'}
          >
            <div className={style.successIcon} data-testid='success-icon'>
              { theme === FEELIX_THEMES.SOLO && <TickIcon color='brand' size='sm'/> }
              { theme === FEELIX_THEMES.MODERN && <SuccessIcon color='currentColor' size='lg'/> }
            </div>
            <Text as='span' tone='neutralStrongest' fontSize={theme === FEELIX_THEMES.SOLO ? 'sm' : 'md'}>
              {feature}
            </Text>
          </Box>
        </li>
    ))}
    </ul>);
